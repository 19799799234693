import {ReactElement} from "react";
import {FinancialEntityType} from "../../__generated__/generated_types";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {Button} from "@zendeskgarden/react-buttons";
import {Apartment} from "@material-ui/icons";
import {TTableRow} from "./types";

/**
We use v 12.3 of handsontable where Custom Component Renderer parameters have no type annotation.
The interface of this component is:
- TD, row, value - provided by handsontable
- displayingRows: TTableRow[] - data store (passed explicitly instead of using react context)
- glTotalRowId: string - id of row that is displayed in the bottom of a table (aka summary row)
- onButtonClicked - function that handles click on a button within a cell
- onCellClicked - function that handles click on the cell itself (click in the cell but outside of button)
- disableButton - boolean intructing to force disable button display
*/
export function DescriptionCellRenderer(...props: any): ReactElement {
    const {TD, row, value, displayingRows,
        disableButton, onButtonClicked,
        glTotalRowId, onCellClicked
    } = props[0];
    let canShowButton = false;
    let rowData:TTableRow | undefined = undefined;
    function setupStyling() {
        TD.className = "";
        if (!displayingRows || row == 0) {
            return;
        }
        rowData = displayingRows[row - 1] as TTableRow; // the table data has extra row - header row. display rows do not have that row. hence shift
        if (!rowData) {
            return;
        }
        if (rowData.type !== FinancialEntityType.Component
            && rowData.type !== FinancialEntityType.Account
            && !rowData.isPropertyRow
            && !rowData.isSubtotalRow
            && rowData.id !== glTotalRowId
        ) {
            TD.className += ` ${css.expandableCol}`;
        }
        if (rowData.isSubtotalRow || rowData.id == glTotalRowId) {
            TD.className += ` ${css.subtotalRow} ${css.subtotalRowFirstCol}`;
        }
        canShowButton = row > 0 && !rowData.isPropertyRow && !rowData.isSubtotalRow;
    }
    setupStyling();

    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (canShowButton && !disableButton && rowData && onButtonClicked) {
            onButtonClicked(rowData);
            event.stopPropagation();
        }
    }

    function handleCellClick() {
        if (rowData && onCellClicked) {
           onCellClicked(rowData);
        }
    }

    return (
        <div className={css.buttonCellWrapper} onClick={handleCellClick}>
            <div dangerouslySetInnerHTML={{__html: value}} />
            {rowData && canShowButton && !disableButton && <Button isBasic onClick={handleButtonClick}><Apartment fontSize="small"/></Button>}
        </div>
    );
}
