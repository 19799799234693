import {ReactElement, useState} from "react";
import {Cell} from "@zendeskgarden/react-tables";
import {Button} from "@zendeskgarden/react-buttons";

export interface ExpandableTextCellProps {
    text: string;
    truncationLength: number;
}

export function ExpandableTextCell(props: ExpandableTextCellProps): ReactElement {
    const willTruncate = props.text.length > props.truncationLength;
    const [isTruncated, setIsTruncated] = useState<boolean>(willTruncate);

    return <Cell isTruncated={isTruncated}>
        {willTruncate &&
            (isTruncated ? <Button isLink aria-label="Show all text" onClick={() => setIsTruncated(false)}>[+]</Button> :
                <Button isLink aria-label="Show only preview text" onClick={() => setIsTruncated(true)}>[-]</Button>)
        }&nbsp;
        {props.text}
    </Cell>;
}