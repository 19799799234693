import React, {useContext} from "react";
import {Route, RouteProps} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import {userIsAdmin} from "../authorization/AuthorizationCheckers";

interface AdminRouteProps extends RouteProps {
    component: any;
}

export const AdminRoute = (props: AdminRouteProps) => {
    const { component: Component, ...rest } = props;
    const { user } = useContext(AuthContext);

    return (
    <Route
        {...rest}
        render={(routeProps) =>
            userIsAdmin(user) ?  (
                <Component {...routeProps} />
            ) : <>Protected</>
        }
    />);
};

