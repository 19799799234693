import {ReactElement, useEffect, useMemo, useState} from "react";
import {IPortfolioFinancialTable, TDataRecord, TTableRow} from "./types";
import {buildDisplayRows, buildPropertyDisplayRows, buildRows} from "./logic";
import {FinancialEntityType, useGetPortfolioSnapshotDataLazyQuery} from "../../__generated__/generated_types";
import {useAccountPropetiesDataLoader} from "./accountPropertiesDataLoader";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import {AccountFilter} from "../../pages/portfolio-financial/components/account-filter/AccountFilter";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {ReactComponent as MaximizeIcon} from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import {ReactComponent as MinimizeIcon} from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import {ToggleOffOutlined, ToggleOn} from "@material-ui/icons";
import {BiFilter} from "react-icons/bi";
import cn from "classnames";
import {Property} from "../../contexts/properties/PropertiesContext";
import {NumbersTable} from "./NumbersTable";
import {AccountPropertiesModal} from "./AccountPropertiesModal";


export function PortfolioFinancialTable(props: IPortfolioFinancialTable): ReactElement {
    const [fetchPortfolioData, {data: portfolioData, loading: portfolioDataLoading}] = useGetPortfolioSnapshotDataLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [data, setData] = useState<Record<string, TDataRecord>>({});
    const [properties, setProperties] = useState<Property[]>();
    const [rows, setRows] = useState<TTableRow[]>();
    const [displayRows, setDisplayRows] = useState<TTableRow[]>();
    const [collapsedRowIds, setCollapsedRowIds] = useState<string[]>();
    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>();
    const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
    const [isZeroSuppressed, setIsZeroSuppressed] = useState<boolean>(true);

    const [showAccountFilter, setShowAccountFilter] = useState(false);
    const [showAccountPropertiesModal, setShowAccountPropertiesModal] = useState<{
        propertyDisplayRows: TTableRow[];
        glTotalRow: TTableRow;
    }>();

    const {loadDataForAccount: loadPropertiesValuesForAccount,
        data: accountPropertiesValues,
        reset: resetPropertiesData} =
        useAccountPropetiesDataLoader({snapshotId: props.snapshotId, propertyIds: props.properties.map(p => p.id)});

    const [lowestReforecastStartMonth, highestReforecastStartMonth] = useMemo(() => {
        if (!properties) {
            return [undefined, undefined];
        }

        const lowestReforecastStartMonth = Math.min(...properties.map(x => x.reforecastStartMonthIndex));
        const highestReforecastStartMonth = Math.max(...properties.map(x => x.reforecastStartMonthIndex));
        return [lowestReforecastStartMonth, highestReforecastStartMonth];
    }, [properties]);

    function triggerCollapsed(tableRow: TTableRow, collapsed: boolean) {
        if (!collapsed) {
            setCollapsedRowIds(prev =>
                prev?.filter(it => it != tableRow.id)
            );
        }
        else {
            setCollapsedRowIds(prev =>
                [...prev ?? [], tableRow.id]
            );
        }
    }

    function handleRowHeaderClicked(tableRow: TTableRow) {
        if (!tableRow.isSubtotalRow
            && !tableRow.isPropertyRow
            && tableRow.type !== FinancialEntityType.Account) {
            triggerCollapsed(tableRow, !collapsedRowIds?.includes(tableRow.id));
        }
    }

    function handleGLButtonClicked(tableRow: TTableRow) {
        if (!tableRow.isSubtotalRow) {
            if (properties) {
                const propertyDisplayRows = buildPropertyDisplayRows(tableRow, properties);
                setShowAccountPropertiesModal({propertyDisplayRows, glTotalRow: tableRow});
            }
        }
    }

    function handleExpandAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            // As per discussion w/ Briant we expand to GL level and let them (users) expand to Property level when they want
            if (row.type === FinancialEntityType.Account) {
                ids.add(row.id);
            }
        }
        setAllRowsExpanded(true);
        setCollapsedRowIds(Array.from(ids));
    }

    function handleCollapseAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            ids.add(row.id);
        }
        setAllRowsExpanded(false);
        setCollapsedRowIds(Array.from(ids));
    }

    useEffect(() => {
        fetchPortfolioData({
            variables: {
                snapshotId: props.snapshotId,
                propertyIds: props.properties.map(p => p.id),
            }
        });
        const rows = buildRows(props.chartOfAccountsTree);
        if (!collapsedRowIds) {
            setCollapsedRowIds(Array.from(new Set<string>(rows.map(row => row.id))));
        }
        if (!selectedAccountIds) {
            setSelectedAccountIds(rows.filter(row => !row.isPropertyRow && !row.isSubtotalRow).map(row => row.id));
        }
        setProperties(undefined);
        setRows(rows);
        resetPropertiesData();
    }, [props.chartOfAccountsTree, props.properties, props.snapshotId]);

    useEffect(() => {
        if (!rows || !selectedAccountIds || !properties) {
            return;
        }
        const displayRows = buildDisplayRows(rows, properties, collapsedRowIds ?? [], selectedAccountIds);
        setDisplayRows(displayRows);
    }, [rows, collapsedRowIds, selectedAccountIds, properties]);

    useEffect(() => {
        if (!portfolioData || portfolioDataLoading) {
            return;
        }
        const data: Record<string, TDataRecord> = {};
        for (const {id, values} of portfolioData.getPortfolioSnapshotData.accountValues) {
            data[id] = {
                id,
                values: [...values],
            };
        }
        setData(data);
        const properties: Property[] = [];
        for (const property of props.properties) {
            let snapshotRfrcstStartMonth = portfolioData.getPortfolioSnapshotData.propertiesData.find(p => p.id == property.id)?.reforecastStartMonthIndex;
            if (snapshotRfrcstStartMonth === undefined) {
                snapshotRfrcstStartMonth = property.reforecastStartMonthIndex;
            }
            properties.push({
                ...property,
                reforecastStartMonthIndex: snapshotRfrcstStartMonth
            });
        }
        setProperties(properties);
    }, [portfolioData, portfolioDataLoading]);


    return (
        <div className={css.tableWrapper}>
            <div className={css.tableActions}>
                <div className={css.actionsLeft}>
                    {
                        allRowsExpanded ?
                            <Button onClick={handleCollapseAll} size="small" isBasic><MinimizeIcon /></Button>
                            : <Button onClick={handleExpandAll} size="small" isBasic><MaximizeIcon /></Button>
                    }

                    <div className={css.legend}>
                        <div className={css.legendBox}></div>
                        <span className={css.legendText}>RFCST/BDGT</span>
                    </div>

                    <div className={css.legend}>
                        <div className={cn(css.legendBox, css.legendHalfShade)}></div>
                        <span className={css.legendText}>Mix of RFCST/ACTUALS</span>
                    </div>
                </div>

                <div className={css.actionsRight}>
                    {props.propertiesFilter}

                    <Button onClick={() => setShowAccountFilter(true)}>
                        <Button.StartIcon><BiFilter /></Button.StartIcon>
                        Filter Accounts
                    </Button>

                    <ToggleButton
                        isBasic
                        onClick={() => setIsZeroSuppressed(!isZeroSuppressed)}
                    >
                        {isZeroSuppressed ?
                            <ToggleOn fontSize="large" />
                            : <ToggleOffOutlined fontSize="large" />
                        }
                        <span style={{marginLeft: ".5rem"}}>Suppress Zeroes</span>
                    </ToggleButton>
                </div>
            </div>
            <div className={css.tableOuter}>
                <NumbersTable
                    lowestReforecastStartMonth={lowestReforecastStartMonth}
                    highestReforecastStartMonth={highestReforecastStartMonth}
                    displayRows={displayRows}
                    budgetYear={props.budgetYear}
                    accountPropertiesLoader={{
                        data: accountPropertiesValues,
                        loadDataForAccount: loadPropertiesValuesForAccount,
                        reset: resetPropertiesData
                    }}
                    accountsData={data}
                    collapsedRowIds={collapsedRowIds}
                    isZeroSuppressed={isZeroSuppressed}
                    handleRowHeaderClicked={handleRowHeaderClicked}
                    handleGLButtonClicked={handleGLButtonClicked}
                />
            </div>
            {showAccountPropertiesModal &&
                <AccountPropertiesModal
                    onClose={() => setShowAccountPropertiesModal(undefined)}
                    lowestReforecastStartMonth={lowestReforecastStartMonth}
                    highestReforecastStartMonth={highestReforecastStartMonth}
                    displayRows={showAccountPropertiesModal.propertyDisplayRows}
                    glTotalRow={showAccountPropertiesModal.glTotalRow}
                    budgetYear={props.budgetYear}
                    accountPropertiesLoader={{
                        data: accountPropertiesValues,
                        loadDataForAccount: loadPropertiesValuesForAccount,
                        reset: resetPropertiesData
                    }}
                    accountsData={data}
                    collapsedRowIds={collapsedRowIds}
                    isZeroSuppressed={isZeroSuppressed}
                    enableSortButton
                />
            }
            {showAccountFilter && selectedAccountIds &&
                <AccountFilter
                    chartOfAccountsTree={props.chartOfAccountsTree}
                    onClose={() => setShowAccountFilter(false)}
                    onConfirm={(ids) => {setSelectedAccountIds(ids); setShowAccountFilter(false);}}
                    selectedAccountIds={selectedAccountIds}
                />
            }
        </div>
    );
}