import React, {ReactElement, useState, useEffect, useMemo} from "react";
import * as css from "../styles/unitTypeManagement.module.scss";
import {useGetUnitTypePmsActualsWithOverridesYearLazyQuery} from "../../../__generated__/generated_types";
import {MediaInput, Message} from '@zendeskgarden/react-forms';
import {Body, Close, Footer, FooterItem, Header, Modal, TooltipModal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Inline} from "@zendeskgarden/react-loaders";
import {MONTHS} from "../../../constants/Months";
import {Property} from "../../../contexts/properties/PropertiesContext";

export type EditUnitTypeNumbersProps = {
    property: Property;
    unitTypeId: string;
    title: string;
    onCancel: () => void;
    onSubmit: (
        unitCountOverrides: Record<number, number | null>,
        occupancyOverrides: Record<number, number | null>,
        inPlaceRentOverrides: Record<number, number | null>
    ) => void;
};

type MetricMonthValue = {
    original: number | null;
    override: number | null;
    newOverride: undefined | number | null; // undefined - no change
};

type MetricValues = Array<MetricMonthValue>;

type MetricsData = {
    unitCount: MetricValues,
    occupancy: MetricValues,
    inPlaceRent: MetricValues;
};

function valueOf(value: MetricMonthValue | undefined): string {
    if (!value) {
        return "";
    }
    let ret = value.original;
    if (value.newOverride !== undefined && value.newOverride !== null) {
        ret = value.newOverride;
    }
    else if (value.override !== null && value.newOverride === undefined) {
        ret = value.override;
    }

    return ret === null ? "" : ret.toString();
}

function valueOfOriginal(value: MetricMonthValue | undefined): string {
    if (!value) {
        return "";
    }

    return value.original === null || value.original === undefined ? "" : value.original.toString();
}

function isOverride(value: MetricMonthValue | undefined): boolean {
    if (value === undefined) {
        return false;
    }
    const ret = value.newOverride !== null && value.newOverride !== undefined
        || value.override !== null && value.newOverride !== null;
    return ret;
}

function numberOrNull(val: undefined | number | null): number | null {
    let ret = val;
    if (ret === undefined) {
        ret = null;
    }
    return ret;
}

function ValueInput(props: {
    isOverride: boolean,
    metricKey: keyof MetricsData,
    monthIndex: number,
    originalValue: string,
    value: string,
    focusedMetricKey: keyof MetricsData | null,
    focusedMonthIndex: number | null,
    tabIndexOffset: number,
    tabIndex: number,
    handleRevertToOriginalMetricValue: (metricKey: keyof MetricsData, monthIndex: number) => void,
    handleSetMetricValue: (metricKey: keyof MetricsData, monthIndex: number, value: string) => void,
    setTooltipReferenceElement: React.Dispatch<React.SetStateAction<{element: HTMLElement, metricKey: keyof MetricsData; monthIndex: number;} | null>>,
    handleKeyDown: (metricKey: keyof MetricsData, monthIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => void,
    handleContextMenu: (metricKey: keyof MetricsData, monthIndex: number, event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}): ReactElement {
    const [value, setValue] = useState<string>(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (props.value !== e.target.value && e.target.value != "") {
            props.handleSetMetricValue(props.metricKey, props.monthIndex, e.target.value);
        }
        else {
            setValue(props.value);
        }
    }

    return (
        <MediaInput
            key={`${props.metricKey}-${props.monthIndex}`}
            value={value}
            type="number"
            isBare
            onChange={e => setValue(e.target.value)}
            onBlur={handleBlur}
            placeholder={props.originalValue}
            wrapperProps={{
                style: {
                    ...(props.isOverride ? {backgroundColor: "var(--magenta-100)"} : {})
                },
                id: `${props.metricKey}-${props.monthIndex}`
            }}
            onKeyDown={e => props.handleKeyDown(props.metricKey, props.monthIndex, e)}
            tabIndex={props.tabIndexOffset + props.tabIndex}
            onContextMenu={e => props.handleContextMenu(props.metricKey, props.monthIndex, e)}
            onMouseUp={(e) => {e.preventDefault(); e.stopPropagation();}}
        />
    );
}

export function EditUnitTypeNumbers(props: EditUnitTypeNumbersProps): ReactElement {
    const [applyClicked, setApplyClicked] = useState(false);
    const [getMetricsData, {data: metricsRawData, loading: metricsRawLoading}] = useGetUnitTypePmsActualsWithOverridesYearLazyQuery({fetchPolicy: "no-cache"});
    const [metricsData, setMetricsData] = useState<MetricsData>();
    const [tooltipReferenceElement, setTooltipReferenceElement] = useState<{element: HTMLElement, metricKey: keyof MetricsData, monthIndex: number;} | null>(null);
    const [tooltipCloseAttempted, setTooltipCloseAttempted] = useState(false);
    const {lastActualMonthIndex} = useMemo(() => {
        let lastActualMonthIndex = props.property.reforecastStartMonthIndex - 1;
        if (lastActualMonthIndex < 0) {
            lastActualMonthIndex = 11;
        }
        return {lastActualMonthIndex};
    }, [props.property]);

    useEffect(() => {
        if (!props.unitTypeId) {
            return;
        }
        getMetricsData({
            variables: {
                propertyId: props.property.id,
                unitTypeId: props.unitTypeId
            }
        });
    }, [props.unitTypeId, props.property.id]);

    useEffect(() => {
        if (!metricsRawData || metricsRawLoading || !metricsRawData.queryUnitTypePMSActualsWithOverridesYear) {
            return;
        }

        const unitCount: MetricMonthValue[] = [];
        const occupancy: MetricMonthValue[] = [];
        const inPlaceRent: MetricMonthValue[] = [];
        for (let i = 0; i < 12; i++) {
            unitCount.push({
                original: numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.unitCount.originals[i]),
                override: numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.unitCount.overrides[i]),
                newOverride: undefined
            });
        }
        for (let i = 0; i < 12; i++) {
            let original = numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.occupancy.originals[i]);
            let override = numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.occupancy.overrides[i]);
            if (original !== null) {
                original *= 100;
                original = Math.round(original * 100) / 100;
            }
            if (override !== null) {
                override *= 100;
                override = Math.round(override * 100) / 100;
            }
            occupancy.push({
                original: original,
                override: override,
                newOverride: undefined
            });
        }
        for (let i = 0; i < 12; i++) {
            let original = numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.inPlaceRent.originals[i]);
            let override = numberOrNull(metricsRawData.queryUnitTypePMSActualsWithOverridesYear.inPlaceRent.overrides[i]);
            if (original !== null) {
                original = Math.round(original * 100) / 100;
            }
            if (override !== null) {
                override = Math.round(override * 100) / 100;
            }
            inPlaceRent.push({
                original: original,
                override: override,
                newOverride: undefined
            });
        }
        setMetricsData({
            unitCount: unitCount,
            occupancy: occupancy,
            inPlaceRent: inPlaceRent
        });
    }, [metricsRawData, metricsRawLoading]);

    function handleSetMetricValue(metricKey: keyof MetricsData, monthIndex: number, value: string) {
        if (!metricsData) {
            return;
        }
        const updated = [...metricsData[metricKey]];
        const item = updated[monthIndex];
        let numVal: number | null = parseFloat(value);
        if (value === "") {
            numVal = null;
        }
        if (item && !Number.isNaN(numVal)) {
            if (numVal !== null && item.override === numVal) {
                item.newOverride = undefined;
            }
            else {
                item.newOverride = numVal;
            }
        }
        setMetricsData(({
            ...metricsData,
            [metricKey]: updated
        }));
    }

    function handleRevertToOriginalMetricValue(metricKey: keyof MetricsData, monthIndex: number) {
        if (!metricsData) {
            return;
        }
        const updated = [...metricsData[metricKey]];
        const item = updated[monthIndex];
        if (item) {
            item.newOverride = null;
        }
        setMetricsData(({
            ...metricsData,
            [metricKey]: updated
        }));
        setTooltipReferenceElement(null);
        setTooltipCloseAttempted(false);

    }

    function handlePreserveOriginalValue(metricKey: keyof MetricsData, monthIndex: number) {
        if (!metricsData) {
            return;
        }
        const updated = [...metricsData[metricKey]];
        const item = updated[monthIndex];
        if (item) {
            item.newOverride = item.original;
        }
        setMetricsData(({
            ...metricsData,
            [metricKey]: updated
        }));
        setTooltipReferenceElement(null);
        setTooltipCloseAttempted(false);

    }

    function handleKeyDown(metricKey: keyof MetricsData, monthIndex: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSetMetricValue(metricKey, monthIndex, event.currentTarget.value);
        }
    }

    function handleContextMenu(metricKey: keyof MetricsData, monthIndex: number, event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.parentElement != null && metricsData) {
            setTooltipReferenceElement({element: event.currentTarget.parentElement, metricKey, monthIndex});
        }
    }

    function handleContextMenuClose() {
        if (tooltipCloseAttempted) {
            setTooltipReferenceElement(null);
            setTooltipCloseAttempted(false);
        }
        else {
            setTooltipCloseAttempted(true);
        }
    }

    function handleSubmit() {
        const unitCountOverrides: Record<number, number | null> = {};
        const occupancyOverrides: Record<number, number | null> = {};
        const inPlaceRentOverrides: Record<number, number | null> = {};
        if (metricsData) {
            for (let i = 0; i < lastActualMonthIndex + 1; i++) {
                const newUnitCountOverride = metricsData.unitCount[i]?.newOverride;
                if (newUnitCountOverride !== undefined) {
                    unitCountOverrides[i] = newUnitCountOverride;
                }
                const newOccupancyOverride = metricsData.occupancy[i]?.newOverride;
                if (newOccupancyOverride !== undefined) {
                    occupancyOverrides[i] = newOccupancyOverride === null ? null : newOccupancyOverride / 100.0;
                }
                const newInPlaceRentOverride = metricsData.inPlaceRent[i]?.newOverride;
                if (newInPlaceRentOverride !== undefined) {
                    inPlaceRentOverrides[i] = newInPlaceRentOverride;
                }
            }

        }
        props.onSubmit(unitCountOverrides, occupancyOverrides, inPlaceRentOverrides);
    }

    function hasChanges(): boolean {
        let ret = false;
        if (!ret && metricsData) {
            ret = ret || (metricsData.unitCount.find(val => val.newOverride !== undefined) !== undefined);
        }
        if (!ret && metricsData) {
            ret = ret || (metricsData.occupancy.find(val => val.newOverride !== undefined) !== undefined);
        }
        if (!ret && metricsData) {
            ret = ret || (metricsData.inPlaceRent.find(val => val.newOverride !== undefined) !== undefined);
        }
        return ret;
    }

    function hasRevenueAffectingChanges(): boolean {
        let ret = false;
        if (!ret && metricsData) {
            ret = ret || metricsData.unitCount[lastActualMonthIndex]?.newOverride !== undefined;
        }
        if (!ret && metricsData) {
            ret = ret || metricsData.occupancy[lastActualMonthIndex]?.newOverride !== undefined;
        }
        if (!ret && metricsData) {
            ret = ret || metricsData.inPlaceRent[lastActualMonthIndex]?.newOverride !== undefined;
        }

        return ret;
    }

    return (
        <Modal onClose={props.onCancel} isLarge style={{minWidth: "80rem", overflow: "unset"}}>
            <Header>
                {props.title}
            </Header>
            <Body>
                {tooltipReferenceElement && metricsData &&
                    <TooltipModal
                        referenceElement={tooltipReferenceElement.element}
                        onClose={() => handleContextMenuClose()}
                        placement="top"
                    >
                        <TooltipModal.Body>
                            {isOverride(metricsData[tooltipReferenceElement.metricKey][tooltipReferenceElement.monthIndex])
                                ?
                                <div style={{cursor: "pointer"}} onClick={() => handleRevertToOriginalMetricValue(tooltipReferenceElement.metricKey, tooltipReferenceElement.monthIndex)}>
                                    Revert to original {tooltipReferenceElement && metricsData ? `(${valueOfOriginal(metricsData[tooltipReferenceElement.metricKey][tooltipReferenceElement.monthIndex])})` : ""}
                                </div>
                                :
                                <div style={{cursor: "pointer"}} onClick={() => handlePreserveOriginalValue(tooltipReferenceElement.metricKey, tooltipReferenceElement.monthIndex)}>
                                    Preserve Current PMS Value
                                </div>
                            }
                        </TooltipModal.Body>
                        <TooltipModal.Close aria-label="Close" />
                    </TooltipModal>
                }
                <form className={css.editForm}>
                    {metricsData &&
                        <table style={{marginTop: "2rem"}} className={css.valuesTable}>
                            <thead>
                                <tr>
                                    <th>
                                        Metric
                                    </th>
                                    {MONTHS.slice(0, lastActualMonthIndex + 1).map(month =>
                                        <th key={month}>
                                            {month}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="unitCount">
                                    <td>
                                        Unit Count
                                    </td>
                                    {MONTHS.slice(0, lastActualMonthIndex + 1).map((_, monthIndex) =>
                                        <td key={`unitCount-${monthIndex}`}>
                                            <ValueInput
                                                key={`unitCount-${monthIndex}`}
                                                tabIndexOffset={100}
                                                tabIndex={monthIndex}
                                                metricKey="unitCount"
                                                monthIndex={monthIndex}
                                                originalValue={valueOfOriginal(metricsData.unitCount[monthIndex])}
                                                value={valueOf(metricsData.unitCount[monthIndex])}
                                                isOverride={isOverride(metricsData.unitCount[monthIndex])}
                                                focusedMetricKey={tooltipReferenceElement === null ? null : tooltipReferenceElement.metricKey}
                                                focusedMonthIndex={tooltipReferenceElement === null ? null : tooltipReferenceElement.monthIndex}
                                                handleRevertToOriginalMetricValue={handleRevertToOriginalMetricValue}
                                                handleSetMetricValue={handleSetMetricValue}
                                                setTooltipReferenceElement={setTooltipReferenceElement}
                                                handleKeyDown={handleKeyDown}
                                                handleContextMenu={handleContextMenu}
                                            />
                                        </td>
                                    )}
                                </tr>
                                <tr key="occupancy">
                                    <td>
                                        Occupancy %
                                    </td>
                                    {MONTHS.slice(0, lastActualMonthIndex + 1).map((_, monthIndex) =>
                                        <td key={monthIndex}>
                                            <ValueInput
                                                key={`occupancy-${monthIndex}`}
                                                tabIndexOffset={200}
                                                tabIndex={monthIndex}
                                                metricKey="occupancy"
                                                monthIndex={monthIndex}
                                                originalValue={valueOfOriginal(metricsData.occupancy[monthIndex])}
                                                value={valueOf(metricsData.occupancy[monthIndex])}
                                                isOverride={isOverride(metricsData.occupancy[monthIndex])}
                                                focusedMetricKey={tooltipReferenceElement === null ? null : tooltipReferenceElement.metricKey}
                                                focusedMonthIndex={tooltipReferenceElement === null ? null : tooltipReferenceElement.monthIndex}
                                                handleRevertToOriginalMetricValue={handleRevertToOriginalMetricValue}
                                                handleSetMetricValue={handleSetMetricValue}
                                                setTooltipReferenceElement={setTooltipReferenceElement}
                                                handleKeyDown={handleKeyDown}
                                                handleContextMenu={handleContextMenu}
                                            />
                                        </td>
                                    )}
                                </tr>
                                <tr key="inPlaceRent">
                                    <td>
                                        In Place Rent
                                    </td>
                                    {MONTHS.slice(0, lastActualMonthIndex + 1).map((_, monthIndex) =>
                                        <td key={monthIndex}>
                                            <ValueInput
                                                key={`inPlaceRent-${monthIndex}`}
                                                tabIndexOffset={300}
                                                tabIndex={monthIndex}
                                                metricKey="inPlaceRent"
                                                monthIndex={monthIndex}
                                                originalValue={valueOfOriginal(metricsData.inPlaceRent[monthIndex])}
                                                value={valueOf(metricsData.inPlaceRent[monthIndex])}
                                                isOverride={isOverride(metricsData.inPlaceRent[monthIndex])}
                                                focusedMetricKey={tooltipReferenceElement === null ? null : tooltipReferenceElement.metricKey}
                                                focusedMonthIndex={tooltipReferenceElement === null ? null : tooltipReferenceElement.monthIndex}
                                                handleRevertToOriginalMetricValue={handleRevertToOriginalMetricValue}
                                                handleSetMetricValue={handleSetMetricValue}
                                                setTooltipReferenceElement={setTooltipReferenceElement}
                                                handleKeyDown={handleKeyDown}
                                                handleContextMenu={handleContextMenu}
                                            />
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    }
                </form>
            </Body>
            <Footer>
                {hasRevenueAffectingChanges() &&
                    <FooterItem>
                        <Message validation="warning">Changes in {MONTHS[lastActualMonthIndex]} affect revenue</Message>
                    </FooterItem>
                }
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!hasChanges() || applyClicked}
                        onClick={() => {setApplyClicked(true); handleSubmit();}}
                    >
                        {
                            applyClicked
                                ? <Inline size={24} aria-label="loading" />
                                : <span>Apply</span>
                        }
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

