import "native-injects";
import React, {useEffect, useState} from "react";
import {
    AccountType,
    AccountV2,
    BudgetComponentType,
    BudgetComponentV2,
    FinancialEntityType,
    GetChartOfAccountsQuery,
    MathOperationType,
    OperationalFinancialMetricsAggregateType,
    useGetChartOfAccountsQuery
} from "../../__generated__/generated_types";

export interface Account {
    id: string;
    accountNumber: string;
    name: string;
    order: number;
    parentId: string;
}

export interface ComponentOp {
    sourceComponentId: string;
    operation: MathOperationType;
}

export interface FinancialEntity {
    id: string;
    name: string;
    number: string;
    parentId: string | undefined;
    budgetComponentType: BudgetComponentType;
    // Indicates if this financial value when
    // rolled up into a component is negated.
    budgetComponentNegate?: boolean;
    componentOps?: ComponentOp[];
    type: FinancialEntityType;
    children: FinancialEntity[];
    order: number;
}

interface ChartOfAccountsInternalState {
    accounts: Account[];
    chartOfAccountsId: string | undefined;
    chartOfAccounts: FinancialEntity[] | undefined;
    chartOfAccountsFlat: FinancialEntity[] | undefined;
    financialEntityInfoByIdMap: Record<string, FinancialEntity>;
    parents: Record<string, FinancialEntity>;
    isIncome: Record<string, boolean>;
    rawData: GetChartOfAccountsQuery | undefined;
    loading: boolean;
    operationalFinancialMetricsAggregateId: string | undefined;
    operationalFinancialMetricsAggregateType: OperationalFinancialMetricsAggregateType | undefined;
    isReady: boolean;
}

export interface CategoryDescriptor {
    id: string,
    type: FinancialEntityType, //'Component' | 'Category' | 'Account',
    name: string,
    budgetComponentType?: 'INCOME' | 'EXPENSE',
}

export interface ChartOfAccountsState extends ChartOfAccountsInternalState {
    getPrevAccountId: (accountId: string) => string | undefined;
    getNextAccountId: (accountId: string) => string | undefined;
    getParentCategories: (firstParentId: string) => CategoryDescriptor[];
    getIsIncome: (id: string) => boolean;
    getFinancialEntityById: (id: string) => FinancialEntity | undefined;
}

const DEFAULT_INTERNAL_STATE: ChartOfAccountsInternalState = {
    accounts: [],
    rawData: undefined,
    chartOfAccountsId: undefined,
    chartOfAccounts: [],
    chartOfAccountsFlat: [],
    financialEntityInfoByIdMap: {},
    parents: {},
    isIncome: {},
    loading: false,
    operationalFinancialMetricsAggregateId: undefined,
    operationalFinancialMetricsAggregateType: undefined,
    isReady: false
};

const ChartOfAccountsStateContext = React.createContext<ChartOfAccountsState | undefined>(undefined);

function useChartOfAccountsState(): ChartOfAccountsState {
    const {data, loading} = useGetChartOfAccountsQuery({
        fetchPolicy: "no-cache"
    });

    const [state, setState] = useState(DEFAULT_INTERNAL_STATE);

    function getPrevAccountId(accountId: string) {
        if (state.accounts === undefined || state.accounts.length === 0) {
            return undefined;
        }
        const accountIndex = state.accounts.findIndex(acc => acc.id === accountId);
        if (accountIndex === 0) {
            return undefined;
        }

        return state.accounts[accountIndex - 1]?.id ?? '';
    }

    function getNextAccountId(accountId: string) {
        if (state.accounts === undefined || state.accounts.length === 0) {
            return undefined;
        }
        const accountIndex = state.accounts.findIndex(acc => acc.id === accountId);
        if (accountIndex === state.accounts.length - 1) {
            return undefined;
        }

        return state.accounts[accountIndex + 1]?.id ?? '';
    }

    function getParentCategories(firstParentId: string): CategoryDescriptor[] {
        const parentCategories = [] as CategoryDescriptor[];

        if (!state.isReady || !state.accounts) {
            return [];
        }

        let fe = state.parents[firstParentId];

        while (fe?.parentId) {
            parentCategories.push({
                id: fe.id,
                name: fe.name,
                type: fe.type,
                budgetComponentType: fe.budgetComponentType === BudgetComponentType.Income ? "INCOME" : "EXPENSE"
            });
            fe = state.parents[fe.parentId];
        }

        if (fe) {
            parentCategories.push({
                id: fe.id,
                name: fe.name,
                type: fe.type,
                budgetComponentType: fe.budgetComponentType === BudgetComponentType.Income ? "INCOME" : "EXPENSE"
            });
        }

        return parentCategories;
    }

    function getIsIncome(entityId: string): boolean {
        return state.isIncome[entityId] ?? false;
    }

    function getFinancialEntityById(entityId: string): FinancialEntity | undefined {
        return state.financialEntityInfoByIdMap[entityId];
    }

    useEffect(
        () => {
            if (data && !loading && data.budgetComponentMappings && data.findFirstChartOfAccounts) {

                const componentGroups = Object.entries(data.budgetComponentMappings.groupBy(row => row.component.id)).sortBy(([, row]) => row.firstElement.component.order);
                // const components = data?.findFirstChartOfAccounts?.budgetComponents;
                const chartOfAccounts = [] as FinancialEntity[];
                const chartOfAccountFlat = [] as FinancialEntity[];
                const financialEntityInfoByIdMap = {} as Record<string, FinancialEntity>;
                const isIncome: Record<string, boolean> = {};

                const accountsCollection = [] as Account[];
                const parents: Record<string, FinancialEntity> = {};

                componentGroups.forEach(([, group]) => {
                    const component: Pick<BudgetComponentV2, "budgetComponentType" | "id" | "name" | "order"> = group.firstElement.component;
                    const componentFE: FinancialEntity = {
                        id: component.id,
                        name: component.name,
                        number: "",
                        order: component.order,
                        componentOps: group.filter(row => row.sourceComponent?.id != undefined && row.sourceComponent?.id != null
                            || row.sourceAccount?.id != undefined && row.sourceAccount?.id != null)
                            .map(row => ({
                                sourceComponentId: row.sourceComponent?.id ?? row.sourceAccount?.id ?? "", // coalesce never hit because of filter before map
                                operation: row.negate ? MathOperationType.Sub : MathOperationType.Add
                            })),
                        budgetComponentType: component.budgetComponentType,
                        type: FinancialEntityType.Component,
                        parentId: undefined,
                        children: []
                    };

                    parents[component.id] = componentFE;
                    isIncome[component.id] = component.budgetComponentType == "INCOME";
                    chartOfAccounts.push(componentFE);
                    chartOfAccountFlat.push(componentFE);
                    financialEntityInfoByIdMap[component.id] = componentFE;


                    function getChildrenAccounts(
                        parentAccount: Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId' | "children">,
                        parentFE: FinancialEntity,
                        isIncomeComponent: boolean,
                        budgetComponentNegate: boolean
                    ): void {
                        parentAccount.children?.sortBy(row => row.order).forEach(child => {
                            const childAccountFE: FinancialEntity = {
                                id: child.id,
                                name: child.glName,
                                number: child.glNumber ?? "",
                                order: child.order,
                                budgetComponentType: component.budgetComponentType,
                                budgetComponentNegate: budgetComponentNegate,
                                type: child.type == AccountType.Account ? FinancialEntityType.Account : FinancialEntityType.Category,
                                parentId: parentAccount.id,
                                children: []
                            };

                            isIncome[childAccountFE.id] = isIncomeComponent;
                            parents[childAccountFE.id] = childAccountFE;
                            parentFE.children.push(childAccountFE);
                            chartOfAccountFlat.push(childAccountFE);

                            if (childAccountFE.type == FinancialEntityType.Account) {
                                accountsCollection.push({
                                    id: childAccountFE.id,
                                    accountNumber: childAccountFE.number,
                                    name: childAccountFE.name,
                                    order: childAccountFE.order,
                                    parentId: parentAccount.id
                                });
                            }
                            else {
                                getChildrenAccounts(child, childAccountFE, isIncomeComponent, budgetComponentNegate);
                            }
                        });
                    }

                    for (const accountRow of group.filter(row => row.sourceAccount != undefined).sortBy(row => row.sourceAccount?.order ?? 0)) {


                        if (!accountRow.sourceAccount) {
                            continue;
                        }

                        const accountFE: FinancialEntity = {
                            id: accountRow.sourceAccount.id,
                            name: accountRow.sourceAccount.glName,
                            number: accountRow.sourceAccount.glNumber ?? "",
                            order: accountRow.sourceAccount.order,
                            budgetComponentType: component.budgetComponentType,
                            budgetComponentNegate: accountRow.negate,
                            type: (accountRow.sourceAccount?.type ?? "") == AccountType.Account ? FinancialEntityType.Account : FinancialEntityType.Category,
                            parentId: accountRow.component.id,
                            children: []
                        };

                        isIncome[accountFE.id] = component.budgetComponentType == "INCOME";
                        parents[accountFE.id] = accountFE;
                        componentFE.children.push(accountFE);
                        chartOfAccountFlat.push(accountFE);
                        financialEntityInfoByIdMap[accountFE.id] = accountFE;

                        if (accountFE.type == FinancialEntityType.Account) {
                            accountsCollection.push({
                                id: accountFE.id,
                                accountNumber: accountFE.number,
                                name: accountFE.name,
                                order: accountFE.order,
                                parentId: accountRow.component.id
                            });
                        }
                        getChildrenAccounts(
                            accountRow.sourceAccount as Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId' | "children">,
                            accountFE,
                            component.budgetComponentType == "INCOME",
                            accountRow.negate
                        );

                    }
                });


                setState({
                    ...state,
                    accounts: accountsCollection.sortBy(account => account.order),
                    rawData: data,
                    chartOfAccountsId: data.findFirstChartOfAccounts.id,
                    chartOfAccounts: chartOfAccounts.sortBy(entity => entity.order),
                    chartOfAccountsFlat: chartOfAccountFlat.sortBy(entity => entity.order),
                    parents,
                    isIncome: isIncome,
                    loading: loading,
                    operationalFinancialMetricsAggregateId: data.findFirstChartOfAccounts.operationalFinancialMetricsAggregateId,
                    operationalFinancialMetricsAggregateType: data?.findFirstChartOfAccounts.operationalFinancialMetricsAggregateType,
                    isReady: true
                });
            }
        },
        [data, loading]
    );

    return {
        ...state,
        getIsIncome,
        getNextAccountId,
        getPrevAccountId,
        getParentCategories,
        getFinancialEntityById,
    };
}

export const ChartOfAccountsProvider: React.FunctionComponent = ({children}) => {
    const state = useChartOfAccountsState();

    return (
        <ChartOfAccountsStateContext.Provider value={state}>
            {children}
        </ChartOfAccountsStateContext.Provider>
    );
};

export function useChartOfAccounts(): ChartOfAccountsState {
    const context = React.useContext(ChartOfAccountsStateContext);

    if (context === undefined) {
        throw new Error('useChartOfAccounts must be used within a ChartOfAccountsProvider');
    }

    return context;
}


function addChildren(parent: FinancialEntity, result: FinancialEntity[], categoryBottom = false) {
    for (const child of parent.children.sortBy("order")) {
        if (!categoryBottom) {
            result.push(child);
        }
        addChildren(child, result);
        if (categoryBottom) {
            result.push(child);
        }
    }
}

export function buildChartOfAccountsFlatComponentsBottomLine(chartOfAccountsTree: FinancialEntity[], categoryBottom = false): FinancialEntity[] {
    const chartOfAccountsFlat: FinancialEntity[] = [];
    for (const record of chartOfAccountsTree.sortBy("order")) {
        addChildren(record, chartOfAccountsFlat, categoryBottom);
        chartOfAccountsFlat.push(record);
    }
    return chartOfAccountsFlat;
}

export function orderByChartOfAccounts<T>(coaSorted: FinancialEntity[], toOrder: Map<string, T>): T[] {
    const resultRows = [];
    for (const coaRow of coaSorted) {
        const resultRow = toOrder.get(coaRow.id);
        if (resultRow) {
            resultRows.push(resultRow);
        }
    }

    return resultRows;
}