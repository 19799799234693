import {ReactElement, useEffect, useState} from "react";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import PropertyDropdown from "../../bulk-update/components/PropertyDropdown";
import {Button} from "@zendeskgarden/react-buttons";
import {PropertyManagementSystem, useCreateSendBudgetJobMutation, useGetPropertyBudgetCompletionsLazyQuery} from "../../../__generated__/generated_types";
import * as css from "./styles/SendBudgetForm.module.scss";
import { Add } from "@material-ui/icons";

export interface SendBudgetFormProps {
    submitted: () => void;
}

export function SendBudgetForm(props: SendBudgetFormProps): ReactElement {
    const [submitJob, {loading}] = useCreateSendBudgetJobMutation({
        fetchPolicy: "no-cache"
    });
    const [fetchBudgetStatus] = useGetPropertyBudgetCompletionsLazyQuery({
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setApprovedPropertyIds(data.getBudgetApprovalStatusForProperties.map(x => x.propertyId));
        }
    });

    const {properties, currentProperty} = useProperties();

    const [propertyIds, setPropertyIds] = useState<string[]>([]);
    const [budgetNameTemplate, setBudgetNameTemplate] = useState<string>("{property}_{budgetYear}_Budget");
    const [approvedPropertyIds, setApprovedPropertyIds] = useState<string[]>();

    useEffect(() => {
        if (currentProperty) {
            fetchBudgetStatus({
                variables: {
                    budgetYear: currentProperty.budgetYear,
                }
            });
        }
    }, [currentProperty]);

    function clear(): void {
        setPropertyIds([]);
        setBudgetNameTemplate("{property}_{budgetYear}_Budget");
    }

    function submit(): void {
        submitJob({
            variables: {
                propertyIds: propertyIds,
                budgetNameTemplate: budgetNameTemplate
            }
        }).then(() => {
            clear();
            props.submitted();
        });
    }

    function addAllApprovedProperties() {
        if (!approvedPropertyIds || approvedPropertyIds.length == 0) {
            return;
        }

        setPropertyIds(prevIds => [...prevIds, ...approvedPropertyIds].dedupe());
    }

    return <form className={css.form}>
        <div className={css.field}>
            <div className={css.propertyLabel}>
                <span>Properties</span>
                <Button
                    isBasic
                    size="small"
                    disabled={!approvedPropertyIds || approvedPropertyIds.length == 0}
                    onClick={addAllApprovedProperties}
                >
                    <Button.StartIcon><Add /></Button.StartIcon>
                    Add Budget Approved Properties
                </Button>
            </div>
            <PropertyDropdown
                    properties={properties ?? []}
                    onPropertySelect={selected => setPropertyIds(selected)}
                    selectedProperties={propertyIds}
                    lockedProperties={{reforecastLocked: [], budgetLocked: []}}
            />
        </div>
        <div className={css.field}>
        <Field>
            <Label>Budget Name Template</Label>
            <Hint>{"Use {property} and {budgetYear} to auto-fill property and budget year."}</Hint>
            <Input
                    type={"text"}
                    placeholder={"{property}_{budgetYear}_Budget"}
                    value={budgetNameTemplate}
                    onChange={(e) => setBudgetNameTemplate(e.target.value)}
            />
        </Field>
        </div>
        <div className={css.buttons}>
            <Button isBasic onClick={clear} disabled={loading}>Clear</Button>
            <Button isPrimary onClick={submit} disabled={propertyIds.length < 1 || budgetNameTemplate === ""}>Submit</Button>
        </div>
    </form>;
}