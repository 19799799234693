import {ReactElement, useRef} from "react";
import {ListJobs, ListJobsDisplayColumn, ListJobsRef} from "./ListJobs";
import {JobType} from "../../../__generated__/generated_types";
import {AdminWrapper} from "../AdminWrapper";
import {SendBudgetForm} from "./SendBudgetForm";
import {Button} from "@zendeskgarden/react-buttons";
import {ReactComponent as IconReload} from '@zendeskgarden/svg-icons/src/16/reload-fill.svg';
import AdminHeader from "../AdminHeader";
import * as css from "./styles/SendBudget.module.scss";
import { useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { useProperties } from "../../../contexts/properties/PropertiesContext";

export function SendBudget(): ReactElement {
    const coa = useChartOfAccounts();
    const properties = useProperties();
    const listJobsRef = useRef<ListJobsRef>(null);

    return <AdminWrapper>
        <AdminHeader title="Send Budget to PMS" subtitle="Create and monitor jobs that send your budget to your property management system." />
        <section className={css.section}>
            <h4 className={css.sectionHeader}>Create Job</h4>
            <SendBudgetForm submitted={() => listJobsRef.current?.reload()}/>
        </section>
        <section className={css.section}>
            <div className={css.compoundSectionHeader}>
                <h4 className={css.sectionHeader}>Jobs List</h4>
                <Button style={{height: "23px"}} isLink onClick={() => listJobsRef.current?.reload()}>Refresh <IconReload /></Button>
            </div>
            {
                coa.chartOfAccountsFlat && properties.properties &&
                    <ListJobs
                        jobType={JobType.SendBudget}
                        ref={listJobsRef}
                        accounts={coa.chartOfAccountsFlat}
                        properties={properties.properties}
                        visibleColumns={[
                            ListJobsDisplayColumn.status,
                            ListJobsDisplayColumn.id,
                            ListJobsDisplayColumn.user,
                            ListJobsDisplayColumn.type,
                            ListJobsDisplayColumn.created,
                            ListJobsDisplayColumn.started,
                            ListJobsDisplayColumn.ended,
                            ListJobsDisplayColumn.errorMessage,
                        ]}

                    />
            }
        </section>
    </AdminWrapper>;
}