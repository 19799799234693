import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/propertyManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";

import {Field, MediaInput} from '@zendeskgarden/react-forms';
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {ReactComponent as AlertIcon} from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';

import "native-injects";
import Card from "../simplified-revenue/components/Card";
import {Property, useProperties} from "../../contexts/properties/PropertiesContext";
import PropertyReforecastStartBulkUpdater from "./components/PropertyReforecastStartBulkUpdater";
import {toast} from "react-toastify";
import {MONTHS} from "../../constants/Months";
import {AuthContext} from "../../contexts/AuthContext";
import {useFetchPmsPropertiesQuery} from "../../__generated__/generated_types";
import {Tooltip} from "@zendeskgarden/react-tooltips";
import {Button} from "@zendeskgarden/react-buttons";
import {Cell, Head, HeaderCell, HeaderRow, Row, Table, Body as TBody} from "@zendeskgarden/react-tables";

type PropertyExt = Property & {
    inAlert: boolean;
};

export default function PropertyManagement(): ReactElement {
    const appStore = useAppStore();
    const auth = useContext(AuthContext);

    const {properties: propertiesRaw} = useProperties();
    const [properties, setProperties] = useState<PropertyExt[]>([]);
    const [filteredProperties, setFilteredProperties] = useState<PropertyExt[]>([]);
    const {data: pmsProperties, loading: pmsPropertiesLoading} = useFetchPmsPropertiesQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache"
    });
    const [latestPMSProperties, setLatestPMSProperties] = useState<Set<string>>();
    const [haveInAlert, setHaveInAlert] = useState(false);
    const bodyRef = useRef(null);

    useEffect(
        () => {
            appStore.set({isLoading: false});
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        if (!propertiesRaw || !pmsProperties || pmsPropertiesLoading) {
            return;
        }

        let latestPMSProperties: Set<string> | undefined = undefined;
        if (pmsProperties.fetchPMSProperties.length > 0) {
            latestPMSProperties = new Set<string>();
            const maxSeenLatest = pmsProperties.fetchPMSProperties.maxValue(property => property.seenLatest);
            for (const pmsProperty of pmsProperties.fetchPMSProperties) {
                if (pmsProperty.seenLatest === maxSeenLatest) {
                    latestPMSProperties.add(pmsProperty.name);
                }
            }
        }
        const properties = propertiesRaw.map(property => ({
            ...property,
            inAlert: Boolean(latestPMSProperties && !latestPMSProperties.has(property.systemName))
        }));

        setLatestPMSProperties(latestPMSProperties);
        const sorted = properties.map(p => ({...p, inAlertNum: p.inAlert ? 1 : 0})).sortByDescending("inAlertNum");
        setProperties(sorted);
        setFilteredProperties(sorted);
        setHaveInAlert(sorted.some(p => p.inAlert));
    }, [propertiesRaw, pmsProperties, pmsPropertiesLoading]);

    const tableBody = useMemo(() => {
        if (filteredProperties.length == 0) {
            return [];
        }

        const ret = filteredProperties.map(property => (
            <Row key={property.id}>
                <Cell className={`${css.cell}`}>
                    <div className={`${css.propertyNameCell}`}>
                        {property.inAlert &&
                            <Tooltip
                                content="Property not found in PMS"
                                popperModifiers={{
                                    flip: {
                                        boundariesElement: bodyRef.current ?? undefined
                                    },
                                }}
                            >
                                <AlertIcon style={{color: "var(--yellow-200)"}} />
                            </Tooltip>
                        }
                        <span>{property.name}</span>
                    </div>
                </Cell>
                <Cell className={`${css.cell} ${css.periodCell}`}>{`${property.reforecastYear}-${MONTHS[property.reforecastStartMonthIndex]}`}</Cell>
            </Row>
        ));
        return ret;
    }, [filteredProperties, latestPMSProperties]);

    function handleReforecastStartMonthUpdateComplete() {
        toast.success(`Reforecast Start Month successfully updated`);
        auth.reloadUserWithProperties();
    }

    /*
     I'll leave it here in case we want email option
     */
    function handleEmailMismatchingProperties(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (!haveInAlert) {
            return;
        }
        const clientName = auth.user?.clientDisplayName ?? auth.user?.clientName;
        if (!clientName) {
            return;
        }
        const propertiesToEmail = properties.filter(p => p.inAlert);
        const bodyStr = propertiesToEmail.map(p => `${p.name}`).join("\r\n");
        const body = encodeURIComponent(bodyStr);
        const subject = encodeURIComponent(`PMS Mismatching properties @ ${clientName}`);
        window.location.href = `mailto:support@vizibly.io?subject=${subject}&body=${body}`;
        e.preventDefault();
    }

    function handleIntercomMismatchingProperties(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (!haveInAlert) {
            return;
        }
        if (window.Intercom !== undefined) {
            const propertiesToEmail = properties.filter(p => p.inAlert);
            const bodyStr = propertiesToEmail.map(p => `- ${p.name}`).join("\r\n");
            const body = "Following properties were not found in Property Management System. Please help resolving the issue.\r\n" + bodyStr;
            // NOTE: Pre-populating messages is only possible with Intercom Inbox Essential & Pro product.
            window.Intercom("showNewMessage", body);
            e.preventDefault();
        }
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Property Management"}
                    subtitle={"Check Property names and update reforecast start month"}
                />
                <Card
                    className={css.updaterControlsCard}
                    titleClassName={css.updaterControlsTitle}
                    title={"Bulk Update Property Reforecast Start"}
                >
                    {propertiesRaw ?
                        <PropertyReforecastStartBulkUpdater
                            properties={propertiesRaw}
                            onUpdateCompleted={() => handleReforecastStartMonthUpdateComplete()}
                        />
                        :
                        <></>
                    }

                </Card>

                <Card
                    title={"Properties"}
                    actions={
                        <div className={css.tableTopControls}>
                            {haveInAlert && latestPMSProperties && latestPMSProperties.size > 0 &&
                                <>
                                    <Button
                                        isDanger
                                        onClick={e => handleIntercomMismatchingProperties(e)}
                                    >
                                        <Tooltip content="Properties marked with this sign are not found in PMS. Financial and Operational numbers refresh is blocked for these properties.">
                                            <AlertIcon style={{color: "var(--yellow-200)", marginRight: "0.5rem"}} />
                                        </Tooltip>
                                        Resolve Properties Mismatch
                                    </Button>
                                </>
                            }
                            <Field className={css.propertySearch}>
                                <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                    if (!propertiesRaw) {
                                        return;
                                    }
                                    setFilteredProperties(properties.filter(p => `${p.name}`.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                                }} />
                            </Field>
                        </div>
                    }
                >
                    <div className={css.propertyTableWrapper}>
                        <Table className={css.propertyTable}>
                            <Head isSticky>
                                <HeaderRow className={css.headerCell}>
                                    <HeaderCell className={`${css.cell} ${css.nameCell}`}>Property</HeaderCell>
                                    <HeaderCell className={`${css.cell} ${css.periodCell}`}>Reforecast Start</HeaderCell>
                                </HeaderRow>
                            </Head>

                            <TBody ref={bodyRef}>
                                {tableBody}
                            </TBody>
                        </Table>
                    </div>
                </Card>
            </div>
        </ThemeProvider>
    );
}