import { ReactElement, useCallback, useEffect, useState } from "react";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as ConfirmIcon} from '@zendeskgarden/svg-icons/src/16/check-lg-stroke.svg';
import {ReactComponent as RevertIcon} from '@zendeskgarden/svg-icons/src/16/edit-undo-stroke.svg';

import { IAccountDriversAndWorksheetDataParams, ISaveData } from "../../../../contexts/account/data/useDriversAndWorksheetData";
import ActionsArray from "../../../../components/actions-array/ActionsArray";
import {GetSinglePropertyDriversAndWorksheetItemsQuery, PayrollPrimaryCompensationItem, useGetPayrollCompensationItemsLazyQuery, useGetPayRolPositionsLazyQuery, useGetWorksheetTypeaheadLazyQuery, useQueryCustomItemsLazyQuery, VersionType} from "../../../../__generated__/generated_types";
import JunctionNode from "./components/formula-nodes/JunctionNode";
import useFormulaBarUpdates from "../../../../contexts/account/data/useFormulaBarUpdates";
import { TDriverMetrics, TDriverMetricsYear, TPendingDriverMetricsYear } from "../../../../contexts/account/data/logic/driversAndWorksheetData";
import { COLORS } from "../../../../constants/Colors";

import useFormulaBarBuilder from "./logic/useFormulaBarBuilder";
import * as css from "./styles/formulaBar.module.scss";
import { ActiveView, ILineItemsMenuOptionsByAccountId, ISetLineItemsMenuOptionsByAccountId, IStashFxBarDirtyStateByAccountId } from "../../../property-drivers/logic/usePropertyDrivers";
import { Inline } from "@zendeskgarden/react-loaders";
import { compItemsCustomNamesMap, IPayrollCompensationItem, IPayrollDriver, TAllAvailableDrivers, TListPayrollPositions } from "../../../../contexts/account/data/utils";
import { IDriversAndWorksheetDataNoCalcEngine } from "../../../../contexts/account/data/useDriversAndWorksheetDataNoCalcEngine";
import { canAddDriversToEmptyFxBar, ModelingMethodName } from "./logic/utils";
import cn from "classnames";


export interface ICustomDriversAndWorksheetData {
    dataLoading: boolean;
    parsedData: TDriverMetrics | undefined;
    rawData: GetSinglePropertyDriversAndWorksheetItemsQuery | undefined;
    saveData: (args: ISaveData) => void;
}

type FormulaBarProps = IAccountDriversAndWorksheetDataParams&{
    driversAndWorksheetData: ICustomDriversAndWorksheetData | IDriversAndWorksheetDataNoCalcEngine,
    showLabelColumn?: boolean,
    versionType: VersionType.Budget|VersionType.Reforecast,
    readOnly: boolean,
    editableFxBarChecker?: (modelingMethod: ModelingMethodName) => boolean,
    // This is added for multi-year. For plan based budgeting, it will get replaced
    // by the global plan id.
    budgetYear: number,
    /**
     * props to communicate with usePropertyDrivers
     */
    isBulkUpdateBar?: boolean;
    isCommitDisabled?: boolean;
    shouldFxBarStashChanges?: boolean;
    stashFxBarDirtyStateByAccountId?: (args: IStashFxBarDirtyStateByAccountId) => void;

    willBulkUpdate?: boolean;
    shareBulkUpdateState?: (drivers: TDriverMetricsYear) => void;

    shouldReceiveBulkUpdate?: boolean;
    bulkUpdateState?: TDriverMetricsYear;
    isPropertyDriversUI?: boolean;

    allAvailableDrivers?: TAllAvailableDrivers;

    lineItemsMenuOptionsByAccountId?: ILineItemsMenuOptionsByAccountId;
    setLineItemsMenuOptionsByAccountId?: (args: ISetLineItemsMenuOptionsByAccountId) => void;

    activeView?: ActiveView;

    selectedAccountIds?: string[];
};

export default function FormulaBar(props: FormulaBarProps):ReactElement {

    const fbUpdates = useFormulaBarUpdates();

    const [payrollDrivers, setPayrollDrivers] = useState<IPayrollDriver[]>();
    const [customItems, setCustomItems] = useState<string[]>([]);
    const [localPayrollPositionsData, setLocalPayrollPositionsData] = useState<TListPayrollPositions>();
    const [localPayrollCompensationItems, setLocalPayrollCompensationItems] = useState<IPayrollCompensationItem[]>();

    const [getPayrollPositionsData, {data: payrollPositionsResult, loading: payrollPositionsDataLoading, error: payrollPositionsDataError}] = useGetPayRolPositionsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getPayrollCompensationItemsData, {data: payrollCompensationItemsResult, loading: payrollCompensationItemsDataLoading, error: payrollCompensationItemsDataError}] = useGetPayrollCompensationItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getWorksheetTypeaheadData, { data: typeaheadData, loading: typeaheadLoading, error: typeaheadLoadingError }] = useGetWorksheetTypeaheadLazyQuery({
        fetchPolicy: "no-cache",
        variables: {
            accountId: props.accountId,
            budgetYear: props.budgetYear,
        },
    });

    const [getCustomItemsData, {data: customItemsResult, loading: customItemsLoading}] = useQueryCustomItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (!props.isPropertyDriversUI) {
            getWorksheetTypeaheadData();
        } else {
            // As long as this account doesn't have an entry already in lineItemsMenuOptionsByAccountId
            if (props.activeView !== ActiveView.selectAccounts && props.lineItemsMenuOptionsByAccountId && !(props.accountId in props.lineItemsMenuOptionsByAccountId)) {
                // And as long as this account is selected & its API call to fetch its typeahead isn't in progress already
                if ((!props.selectedAccountIds || props.selectedAccountIds.includes(props.accountId)) && !typeaheadLoading) {
                    // And as long as it's not the bulk-update bar
                    if (props.accountId !== 'dummy-account-id') {
                        getWorksheetTypeaheadData();
                    }
                }
            }
        }
    }, [props.lineItemsMenuOptionsByAccountId, props.accountId, props.activeView, props.isPropertyDriversUI]);

    useEffect(() => {
        if (typeaheadData && (props.setLineItemsMenuOptionsByAccountId !== undefined)) {
            props.setLineItemsMenuOptionsByAccountId({
                accountId: props.accountId,
                worksheetTypeAhead: typeaheadData.worksheetTypeahead,
            });
        }
    }, [typeaheadData]);

    useEffect(() => {
        if (!props.allAvailableDrivers) {
            getPayrollPositionsData();
            getPayrollCompensationItemsData();
            getCustomItemsData();
        }
    }, [props.allAvailableDrivers]);

    useEffect(() => {
        if (props.allAvailableDrivers) {
            return;
        }

        if (payrollPositionsDataLoading && !payrollPositionsResult?.listPayrollPositions && payrollPositionsDataError) {
            return;
        }

        setLocalPayrollPositionsData(payrollPositionsResult?.listPayrollPositions);
    }, [payrollPositionsDataLoading, payrollPositionsResult, payrollPositionsDataError, props.allAvailableDrivers]);

    useEffect(() => {
        if (customItemsResult && !customItemsLoading) {
            setCustomItems(customItemsResult.queryCustomItems.map(it => it.name));
        }
    }, [customItemsResult, customItemsLoading]);


    useEffect(() => {
        if (props.allAvailableDrivers) {
            return;
        }

        if (payrollCompensationItemsDataLoading || !payrollCompensationItemsResult || payrollCompensationItemsDataError) {
            return;
        }

        const staticPayrollCompensationItems: IPayrollCompensationItem[] = [
            { customName: null, id: PayrollPrimaryCompensationItem.Bonus, type: PayrollPrimaryCompensationItem.Bonus },
            { customName: null, id: PayrollPrimaryCompensationItem.Compensation, type: PayrollPrimaryCompensationItem.Compensation },
            { customName: null, id: PayrollPrimaryCompensationItem.Overtime, type: PayrollPrimaryCompensationItem.Overtime },
        ];

        let allPayrollCompItems: IPayrollCompensationItem[] = [
            ...staticPayrollCompensationItems,
            ...payrollCompensationItemsResult.listCompensationItems,
        ];

        allPayrollCompItems = allPayrollCompItems.map((payrollCompItem: IPayrollCompensationItem) => ({
            ...payrollCompItem,
            customName: compItemsCustomNamesMap[payrollCompItem.type],
        }));

        setLocalPayrollCompensationItems(allPayrollCompItems);
    }, [payrollCompensationItemsDataLoading, payrollCompensationItemsResult, payrollCompensationItemsDataError, props.allAvailableDrivers]);

    // Set payroll drivers when both positions and compensations are ready
    useEffect(() => {
        if (!props.allAvailableDrivers) {
            if (localPayrollCompensationItems && localPayrollPositionsData) {
                const tempPayrollDrivers: IPayrollDriver[] = localPayrollCompensationItems.map((compItem) => ({
                    ...compItem,
                    positions: localPayrollPositionsData,
                }));

                setPayrollDrivers(tempPayrollDrivers);
            }
        }
    }, [localPayrollCompensationItems, localPayrollPositionsData, props.allAvailableDrivers]);

    useEffect(
        () => {
            if (!props.driversAndWorksheetData.parsedData) {
                return;
            }

            fbUpdates.registerParsedDrivers(props.driversAndWorksheetData.parsedData.drivers);

        },
        [props.driversAndWorksheetData.parsedData?.drivers]
    );

    useEffect(() => {
        if (canAddDriversToEmptyFxBar && props.readOnly === false && props.stashFxBarDirtyStateByAccountId !== undefined && props.accountId) {
            props.stashFxBarDirtyStateByAccountId({
                accountId: props.accountId,
                dirtyDrivers: fbUpdates.parsedFormDrivers,
                originalDrivers: fbUpdates.parsedOriginalFormDrivers,
                isWorksheetDeleted: fbUpdates.isUserRequestingWorksheetDeletion,
            });
        }
    }, [
        fbUpdates.parsedFormDrivers,
        fbUpdates.parsedOriginalFormDrivers,
        props.readOnly,
        props.stashFxBarDirtyStateByAccountId === undefined,
        props.accountId,
    ]);

    /**
     * Share db state and dirty state with usePropertyDrivers so it can calculate pendingUpdates for each Fx bar
    */
    useEffect(() => {
        if (props.stashFxBarDirtyStateByAccountId !== undefined && props.shouldFxBarStashChanges && props.accountId) {
            props.stashFxBarDirtyStateByAccountId({
                accountId: props.accountId,
                dirtyDrivers: fbUpdates.parsedFormDrivers,
                originalDrivers: fbUpdates.parsedOriginalFormDrivers,
                isWorksheetDeleted: fbUpdates.isUserRequestingWorksheetDeletion,
            });
        }
    }, [fbUpdates.parsedFormDrivers, fbUpdates.parsedOriginalFormDrivers, props.accountId, props.shouldFxBarStashChanges, props.stashFxBarDirtyStateByAccountId]);

    /**
     * If the current formula bar is a bulk-update bar, and if it's to bulk-apply its drivers to other formula bars,
     *  then it needs to share its dirty state with the usePropertyDrivers hook
     */
    useEffect(() => {
        /**
         * TODO (later): See if the bulk-update formula bar can be stopped from re-rendering
         * as that causes it to lose its state
         *  - If that's not possible, move its state one level up and reuse that conditionally
        */
        let canProceed = false;
        for (const val of Object.values(fbUpdates.parsedFormDrivers)) {
            if (val instanceof Array && val.length > 0) {
                canProceed = true;
            }
        }

        if (!canProceed) return;

        if (props.isBulkUpdateBar && props.willBulkUpdate && props.shareBulkUpdateState) {
            props.shareBulkUpdateState(fbUpdates.parsedFormDrivers);
        }
    }, [fbUpdates.parsedFormDrivers, props.isBulkUpdateBar, props.willBulkUpdate, props.shareBulkUpdateState]);


    /**
     * If this editable formula bar should receive bulk updates, then call the useFormulaBarUpdates method that does that
     */
    useEffect(() => {
        if (!props.isBulkUpdateBar && props.shouldReceiveBulkUpdate && props.bulkUpdateState) {
            fbUpdates.applyBulkUpdate(props.bulkUpdateState);
        }
    }, [props.isBulkUpdateBar, props.shouldReceiveBulkUpdate, props.bulkUpdateState]);

    /**
     * Re-fetch worksheet typeahead every time the user saves formula bar dirty state
     * Only needed for the account view, not for property drivers
     *
     * worksheetLineItems is the key dependency here, since it's a list, we convert it to a string to
     * avoid any surprises with reference
     */
    const worksheetLineItems = fbUpdates.parsedOriginalFormDrivers.worksheet.map((e) => e.description).sort().toString();
    useEffect(() => {
        if (!props.isPropertyDriversUI) {
            getWorksheetTypeaheadData();
        }
    }, [props.isPropertyDriversUI, worksheetLineItems]);

    /**
     * We track worksheet deletions differently than driver updates. Whenever the user deletes a worksheet,
     * isUserRequestingWorksheetDeletion is set to true, and this is an unsaved change made to the formula bar
     */
    const hasUnsavedChanges = () => {
        const val = fbUpdates.isUserRequestingWorksheetDeletion || !(Object.values(fbUpdates.pendingUpdates)
                .every((category: TPendingDriverMetricsYear) => {
                    return Object.values(category).every((array) => array === null || (array instanceof Array && array.length === 0));
                }));
        return val;
    };

    /**
     * If the user is specifically requesting worksheet deletion by hitting the DELETE key on a worksheet node,
     *  then set `isWorksheetDriven` to false regardless of what the original data says
     */
    const isWorksheetDriven = fbUpdates.isUserRequestingWorksheetDeletion ? false : (props.driversAndWorksheetData.rawData?.singlePropertyAccount.worksheetDriver?.isDriven || false);

    const formulaBarBuilder = useFormulaBarBuilder({
        accountId: props.accountId,
        budgetYear: props.budgetYear,
        allAvailableDrivers: props.allAvailableDrivers || { payroll: payrollDrivers, customItems: customItems },
        rawData: props.driversAndWorksheetData.rawData,
        drivers: fbUpdates.parsedFormDrivers,
        isWorksheetDriven,
        year: props.year,
        version: props.versionType,
        readOnly: props.readOnly,
        fbUpdates,
        worksheetTypeAhead: typeaheadData?.worksheetTypeahead,
        isBulkUpdateBar: props.isBulkUpdateBar,
        isPropertyDriversUI: props.isPropertyDriversUI,
        editableFxBarChecker: props.editableFxBarChecker,
        selectedAccountIds: props.selectedAccountIds ?? [],
        currentPropertyId: props.currentPropertyId
    });

    const onCommit = ():void => {
        if (props.accountId
            && props.currentPropertyId
            && props.driversAndWorksheetData.parsedData?.year) {
            props.driversAndWorksheetData.saveData({
                isUserRequestingWorksheetDeletion: fbUpdates.isUserRequestingWorksheetDeletion,
                pendingUpdates: fbUpdates.pendingUpdates,
                accountId: props.accountId,
                propertyId: props.currentPropertyId,
                year: props.driversAndWorksheetData.parsedData?.year,
                versionType: props.versionType,
            });
        }
    };

    const onRevert = ():void => {
        fbUpdates.resetFormulaBar();
    };

    const renderFormulaBarNodes = useCallback(
        () => {
            const lastNodeIndex = formulaBarBuilder.nodes.length - 1;
            const isPayrollNode = (nodeId: string) => nodeId === "payroll_calcs_node";

            if (props.isPropertyDriversUI) {
                if (!fbUpdates.isReady) {
                    return <Inline className={css.fxBarLoader} aria-label="loading"/>;
                } else if (formulaBarBuilder.nodes.length === 0 && props.readOnly) {
                    return <span className={css.emptyFxBarText}>&ndash;</span>;
                }
            }

            return formulaBarBuilder.nodes.map((formulaBarNode, index) =>
                <div className={css.nodesColumnItem} key={formulaBarNode.key}>
                    {formulaBarNode}
                    {index !== lastNodeIndex && !isPayrollNode(formulaBarNode.props.nodeId)
                        ? (
                            <JunctionNode
                                accountId={props.accountId}
                                budgetYear={props.budgetYear}
                                locked={true}
                                fbUpdates={fbUpdates}
                            />
                        )
                        : <></>
                    }
                </div>
            );
        },
        [formulaBarBuilder.nodes, props.accountId, props.budgetYear, props.isPropertyDriversUI, props.readOnly, fbUpdates.isReady]
    );

    const actionIconsColor = hasUnsavedChanges() ? COLORS.PRIMARY_500 : COLORS.GREY_250;

    const worksheetTypeAhead = props.lineItemsMenuOptionsByAccountId?.[props.accountId] || typeaheadData?.worksheetTypeahead;

    return (
            <div className={css.formulaBar}>
                {/* Explicitly checking for false since it's an optional prop */}
                {!(props.showLabelColumn === false) && <div className={css.labelColumn}>
                    <span className={css.functionIcon}>fx</span>
                    <span className={css.functionEquals}>=</span>
                </div>}
                {/* {!fbUpdates.isReady && <Inline className={css.fxBarLoader} />} */}
                <div className={cn(css.nodesColumn, "formula-bar-node-columns")}>
                    {renderFormulaBarNodes()}
                    {!props.readOnly
                        ? (
                            <JunctionNode
                                accountId={props.accountId}
                                budgetYear={props.budgetYear}
                                allAvailableDrivers={props.allAvailableDrivers || {payroll: payrollDrivers, customItems: customItems}}
                                isWorksheetDriven={isWorksheetDriven}
                                locked={false}
                                fbUpdates={fbUpdates}
                                isBulkUpdateBar={props.isBulkUpdateBar}
                                selectedAccountIds={props.selectedAccountIds}
                                isPropertyDriversUI={props.isPropertyDriversUI}
                                worksheetTypeAhead={worksheetTypeAhead}
                                editableFxBarChecker={props.editableFxBarChecker}
                                versionType={props.versionType}
                                currentPropertyId={props.currentPropertyId}
                            />
                        )
                        : <></>
                    }
                </div>
                {
                    !props.readOnly
                            ? <div className={css.actionsColumn}>
                                <ActionsArray disabled={!hasUnsavedChanges()}>
                                    <IconButton onClick={onRevert} size='small'>
                                        <RevertIcon color={actionIconsColor}/>
                                    </IconButton>
                                    {props.isCommitDisabled ? (
                                        <></>
                                    ) : (
                                        <IconButton onClick={onCommit} size='small'>
                                            <ConfirmIcon color={actionIconsColor}/>
                                        </IconButton>
                                    )}
                                </ActionsArray>
                            </div>
                            : <></>
                }
            </div>
    );
}
