import {ReactElement, ReactNode} from "react";
import * as css from "./styles/AdminWrapper.module.scss";
import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";

export function AdminWrapper(props: {children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[]}): ReactElement {
    return <div className={css.adminWrapper}>
        <ThemeProvider theme={ViziblyTheme}>
            {props.children}
        </ThemeProvider>
    </div>;
}