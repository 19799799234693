import {ReactElement, useMemo} from "react";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {Button} from "@zendeskgarden/react-buttons";
import {ReactComponent as ChevronDown} from '@zendeskgarden/svg-icons/src/16/chevron-down-fill.svg';
import {ReactComponent as ChevronUp} from '@zendeskgarden/svg-icons/src/16/chevron-up-fill.svg';
import {ReactComponent as Sort} from '@zendeskgarden/svg-icons/src/16/sort-stroke.svg';

/**
We use v 12.3 of handsontable where Custom Component Renderer parameters have no type annotation.
The interface of this component is:
- TD, col, row, value - provided by handsontable
- displayingRows: TTableRow[] - data store (passed explicitly instead of using react context)
- lowestReforecastStartMonth, highestReforecastStartMonth - part of data store to decide on cell styling
- glTotalRowId: string - id of row that is displayed in the bottom of a table (aka summary row)
- enableSortButton - explicitly saying if to show a sort button
                    NOTE: sort button is only enabled for top row (aka header row)
- sortType, sortColumn - drives the display of sort button
- sort - a function (callback) to notify wrapping component that user clicked the sort button
*/
export function DataCellRenderer(...props: any): ReactElement {
    const {TD, col, row, value,
        lowestReforecastStartMonth,
        highestReforecastStartMonth,
        displayingRows,
        glTotalRowId,
        sort, enableSortButton,
        sortType, sortColumn
    } = props[0];
    function setupStyling() {
        const budgetStartColumn = 13;
        const summaryStartColumn = 25;
        // add thick vertical bar to left of budget year start column
        TD.className = "";
        if (col == budgetStartColumn) {
            TD.className += ` ${css.budgetCell}`;
        }

        if (col >= summaryStartColumn) {
            TD.className += ` ${css.summaryColumn}`;
        }

        if (col == summaryStartColumn) {
            TD.className += ` ${css.summaryColumnFirst}`;
        }

        if (!displayingRows || row == 0 || lowestReforecastStartMonth == undefined || highestReforecastStartMonth == undefined) {
            return;
        }

        const isMixedReforecastColumn = col > lowestReforecastStartMonth && col <= highestReforecastStartMonth;

        const rowData = displayingRows[row - 1]; // the table data has extra row - header row. display rows do not have that row. hence shift

        if (!rowData) {
            return;
        }

        // shade cells for a property row that fall within their reforecast months or the budget year
        if (row > 0 &&
            col > highestReforecastStartMonth &&
            col < summaryStartColumn ||
            (rowData.reforecastStartMonthIndex !== undefined && rowData.isPropertyRow && col > rowData.reforecastStartMonthIndex && col < summaryStartColumn)
        ) {
            TD.className += ` ${css.shadedCellFull}`;
        }

        if (row > 0 && isMixedReforecastColumn && !rowData.isPropertyRow) {
            TD.className += ` ${css.shadedCellHalf}`;
        }

        // add styling for subtotal rows
        if (rowData.isSubtotalRow || rowData.id === glTotalRowId) {
            TD.className += ` ${css.subtotalRow}`;
        }
    }

    setupStyling();

    function handleSortClick() {
        if (sort) {
            sort(col);
        }
    }

    const sortIcon = useMemo(() => {
        let sortIcon = <Sort/>;
        if (sortColumn === col) {
            if (sortType === "asc") {
                sortIcon = <ChevronUp/>;
            } else if (sortType === "desc") {
                sortIcon = <ChevronDown/>;
            }
        }
        return sortIcon;
    }, [col, sortColumn, sortType]);

    return (
        row === 0 ?
            <div className={css.buttonWithSortCellWrapper}>
                <div dangerouslySetInnerHTML={{__html: value}} />
                {enableSortButton && <Button isBasic onClick={handleSortClick}>{sortIcon}</Button>}
            </div>
            :
            value
    );
}
