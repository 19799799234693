import {ForwardedRef, forwardRef, ReactElement, useEffect, useImperativeHandle, useState} from "react";
import {BackgroundJob, JobStatus, JobType, useListJobsLazyQuery} from "../../../__generated__/generated_types";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {Pagination} from "@zendeskgarden/react-pagination";
import * as css from "./styles/css.module.scss";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../../constants/Colors";
import { Check, ErrorOutline } from "@material-ui/icons";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import brandV from "../../../assets/icons/brand/flap_char.png";
import BarLoader from "../../../components/bar-loader/BarLoader";
import {ExpandableTextCell} from "../../../components/zendesk/table/ExpandableTextCell";
import JobDetails from "./JobDetails";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../contexts/properties/PropertiesContext";

interface ListJobsFilter {
    jobType: JobType;
    userId?: string;
}

export enum ListJobsDisplayColumn {
    "id",
    "user",
    "type",
    "status",
    "created",
    "started",
    "ended",
    "errorMessage",
    "details",
}

export interface ListJobsProps {
    jobType: JobType;
    userId?: string;
    visibleColumns?: ListJobsDisplayColumn[];
    customFirstJob?: BackgroundJob;
    accounts?: FinancialEntity[];
    properties?: Property[];
}

export interface ListJobsRef {
    reload: () => void;
}

export const ListJobs = forwardRef(function ListJobs(props: ListJobsProps, ref: ForwardedRef<ListJobsRef>): ReactElement {
    const {jobType, userId, visibleColumns} = props;

    const [listJobs, {data, loading}] = useListJobsLazyQuery({
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
    });
    const [page, setPage] = useState<number>(1);

    const filters: ListJobsFilter = {
        jobType: jobType
    };
    if(userId) {
        filters["userId"] = userId;
    }

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                if(page === 1) {
                    listJobs({
                        variables: {
                            ...filters,
                            page: 1,
                            take: 10
                        }
                    });
                } else {
                    setPage(1);
                }
            }
        };
    }, [page]);

    useEffect(() => {
        listJobs({
            variables: {
                ...filters,
                page: page,
                take: 10
            }
        });
    }, [jobType, page]);

    function formatDateTime(value: Date | string): string {
        if(typeof value === "string") {
            value = new Date(value);
        }

        const formatter = Intl.DateTimeFormat("en-US", {
            dateStyle: "short",
            timeStyle: "short"
        });
        return formatter.format(value);
    }

    function renderStatusIcon(status: JobStatus): ReactElement {
        switch (status) {
            case JobStatus.Completed:
                return <Check htmlColor={COLORS.GREEN_500} />;
            case JobStatus.Failed:
                return <ErrorOutline htmlColor={COLORS.NEGATIVE_COLOR} />;
            default:
                return <Inline color={COLORS.PRIMARY_500} />;
        }
    }

    function displayUsername(job: BackgroundJob): ReactElement {
        if (!job.userEmail || job.userEmail?.includes("@vizibly.io")) {
            return <><img src={brandV} className={css.brandV}/>System</>;
        }

        if (job.userFirstName && job.userLastName) {
            return <>{job.userFirstName} {job.userLastName}</>;
        }

        if (job.userEmail) {
            return <>{job.userEmail}</>;
        }

        return <></>;
    }

    function renderBackgroundJobRow(i: BackgroundJob): ReactElement {
        return <Row key={i.id}>
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.status)) &&
                <Cell className={css.statusIconCell}>
                    <Tooltip
                            type="light"
                            size="small"
                            placement="top-start"
                            content={i.status}
                    >
                        <span>{renderStatusIcon(i.status)}</span>
                    </Tooltip>
                </Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.id)) &&
                <Cell style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{i.id}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.user)) &&
                <Cell>{displayUsername(i)}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.type)) &&
                <Cell>{i.jobType}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.created)) &&
                <Cell>{formatDateTime(i.createdAt)}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.started)) &&
                <Cell>{i.started ? formatDateTime(i.started) : ""}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.ended)) &&
                <Cell>{i.ended ? formatDateTime(i.ended) : ""}</Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.details)) && props.accounts && props.properties &&
                <Cell>
                    <JobDetails job={i} accounts={props.accounts} properties={props.properties} />
                </Cell>
            }
            {
                (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.errorMessage)) &&
                <ExpandableTextCell text={i.errorMessage ? i.errorMessage : ""} truncationLength={50} />
            }
        </Row>;
    }

    let body: JSX.Element | JSX.Element[] = <Row><Cell colSpan={7}>Loading...</Cell></Row>;
    if(!data?.listJobs || data.listJobs.items.length == 0) {
        body = <Row><Cell colSpan={7}>{loading ? "Fetching jobs..." : "No Jobs Yet"}</Cell></Row>;
    } else {
        body = data.listJobs.items.map(i => renderBackgroundJobRow(i));
    }

    return <div className={css.jobListWrapper}>
        <Table className={css.table}>
            <Head>
                <HeaderRow className={css.headerRow}>
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.status)) &&
                        <HeaderCell>Status</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.id)) &&
                        <HeaderCell>Job Id</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.user)) &&
                        <HeaderCell>User</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.type)) &&
                        <HeaderCell>Job Type</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.created)) &&
                        <HeaderCell>Created</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.started)) &&
                        <HeaderCell>Started</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.ended)) &&
                        <HeaderCell>Ended</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.details)) &&
                        <HeaderCell>Details</HeaderCell>
                    }
                    {
                        (!visibleColumns || visibleColumns.includes(ListJobsDisplayColumn.errorMessage)) &&
                        <HeaderCell>Message</HeaderCell>
                    }
                </HeaderRow>
            </Head>
            <Body className={css.tBody}>
                <BarLoader loading={loading} />
                {props.customFirstJob &&
                    renderBackgroundJobRow(props.customFirstJob)
                }
                {body}
            </Body>
        </Table>
        {data?.listJobs &&
            <Pagination
                    currentPage={data.listJobs.page}
                    totalPages={data.listJobs.maxPage === 0 ? data.listJobs.page : data.listJobs.maxPage}
                    onChange={page => setPage(page)}
            />
        }
    </div>;
});