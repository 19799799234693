import { ReactElement, useEffect, useState } from "react";
import { Body, Close, Header, Modal } from "@zendeskgarden/react-modals";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { GetSinglePropertyDriversAndWorksheetItemsQuery, VersionType, useGetFinancialValuesForYearLazyQuery, useGetSinglePropertyDriversAndWorksheetItemsLazyQuery } from "../../../__generated__/generated_types";
import { IAccountSummaryData } from "../../workflows/account/AccountSummaryContext";
import AccountTable from "../../../components/account-table/AccountTable";
import YearMonthRange from "../../../components/year-month-range/YearMonthRange";
import FormulaBar from "../../workflows/account/formula-bar/FormulaBar";
import { TDriverMetrics, hydrateDriverAssumptions } from "../../../contexts/account/data/logic/driversAndWorksheetData";
import cssAccountView from "../../workflows/account/styles/styles.module.scss";
import { buildYearSummary } from "../../../components/account-table/logic/AccountTableHelpers";
import { getModelingMethodLabel, IFinancialsCOATableRow } from "./helpers";
import { Button } from "@zendeskgarden/react-buttons";
import css from "../styles/table.module.scss";
import cn from "classnames";
import { ReactComponent as ChevronLeftIcon } from "@zendeskgarden/svg-icons/src/16/chevron-left-stroke.svg";
import { ReactComponent as ChevronRightIcon } from "@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg";
import styles from "./accountdetails.module.scss";
import {formatterGlName} from "../../../utils/formatters";

interface AccountDetailsModalProps {
    property: Property;
    accountId: string;
    accounts: IFinancialsCOATableRow[];
    onClose: () => void;
}

export default function AccountDetailsModal({
    property,
    accountId: initialAccountId,
    accounts,
    onClose
}: AccountDetailsModalProps): ReactElement {
    const getCurrentIndex = (accountId: string) => accounts.findIndex(a => a.id === accountId);

    const [currentAccount, setCurrentAccount] = useState(accounts[getCurrentIndex(initialAccountId)]);


    const handleNext = () => {
        if (!currentAccount) {
            return;
        }
        const currentIndex = getCurrentIndex(currentAccount.id);
        const nextIndex = currentIndex === accounts.length - 1 ? 0 : currentIndex + 1;
        setCurrentAccount(accounts[nextIndex]);
    };

    const handlePrev = () => {
        if (!currentAccount) {
            return;
        }
        const currentIndex = getCurrentIndex(currentAccount.id);
        const prevIndex = currentIndex === 0 ? accounts.length - 1 : currentIndex - 1;
        setCurrentAccount(accounts[prevIndex]);
    };

    const [accountViewCombinedData, setAccountViewCombinedData] = useState<{
        driversAndWorksheetData: GetSinglePropertyDriversAndWorksheetItemsQuery,
        financialYearValues: IAccountSummaryData,
    }>();
    const [accountViewParsedData, setAccountViewParsedData] = useState<TDriverMetrics>();

    const [getFinancialValues, {data: financialValues, loading: financialValuesLoading}] = useGetFinancialValuesForYearLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getDriverAndWorksheetData, {data: driversAndWorksheetData, loading: driversAndWorksheetDataLoading}] = useGetSinglePropertyDriversAndWorksheetItemsLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!currentAccount) {
            return;
        }
        const years = [
            {year: property.budgetYear, versionType: VersionType.Budget},
            {year: property.reforecastYear, versionType: VersionType.Reforecast},
            {year: property.reforecastYear, versionType: VersionType.Actuals},
            {year: property.reforecastYear - 1, versionType: VersionType.Actuals},
            {year: property.reforecastYear - 2, versionType: VersionType.Actuals},
            {year: property.reforecastYear - 3, versionType: VersionType.Actuals},
        ];

        getDriverAndWorksheetData({
            variables: {
                propertyId: property.id,
                accountId: currentAccount.id,
                year: property.budgetYear,
                versionType: VersionType.Budget
            }
        });
        getFinancialValues({
            variables: {
                propertyIds: [property.id],
                entityIds: currentAccount?.id,
                years,
            }
        });
    }, [property.id, currentAccount]);

    useEffect(() => {
        if (!financialValues || financialValuesLoading || !driversAndWorksheetData || driversAndWorksheetDataLoading) {
            return;
        }
        const ctxData = buildYearSummary(financialValues.financialValuesForYear, property.reforecastStartMonthIndex);
        setAccountViewCombinedData({driversAndWorksheetData, financialYearValues: ctxData});
        setAccountViewParsedData(hydrateDriverAssumptions(driversAndWorksheetData));
    }, [financialValues, financialValuesLoading, driversAndWorksheetData, driversAndWorksheetDataLoading]);

    return (
        <Modal onClose={onClose} className={styles.modal}>
            <Header>
                <div className={styles.headerContainer}>
                    {currentAccount &&
                        <div className={cn(css.modelingMethodCell, styles.accountTitle)}>
                            <span className={styles.accountName}>{formatterGlName(currentAccount.name, currentAccount.number)}</span>
                            {currentAccount.modelingType.length === 0 ? "" :
                            currentAccount.modelingType.length === 1 ?
                                <span className={cn(css.methodBadge, currentAccount.hasOverrides ? css.hasOverride : undefined)}>
                                    {getModelingMethodLabel(currentAccount.modelingType[0])}
                                </span> :
                                <span className={css.methodBadge}>2+</span>
                            }
                        </div>
                    }
                    <div className={styles.navigationButtons}>
                        <Button onClick={handlePrev}>
                            <ChevronLeftIcon style={{ marginRight: "4px" }} />
                            Previous
                        </Button>
                        <Button onClick={handleNext}>
                            Next
                            <ChevronRightIcon style={{ marginLeft: "4px" }} />
                        </Button>
                    </div>
                </div>
            </Header>
            <Body style={{ overflowX: "hidden" }}>
                {accountViewCombinedData && currentAccount &&
                    <>
                        <div className={cssAccountView.accountFormulaBar}>
                            <YearMonthRange
                            startYear={property.budgetYear}
                            startMonthIdx={0}
                            endYear={property.budgetYear}
                            endMonthIdx={11}
                            className={cssAccountView.formulaDateRange}
                        />
                            <FormulaBar
                                budgetYear={property.budgetYear}
                                year={property.budgetYear}
                                versionType={VersionType.Reforecast}
                                currentPropertyId={property.id}
                                accountId={currentAccount?.id}
                                readOnly={true}
                                driversAndWorksheetData={{
                                    dataLoading: driversAndWorksheetDataLoading,
                                    parsedData: accountViewParsedData,
                                    rawData: accountViewCombinedData.driversAndWorksheetData,
                                    saveData: () => {/* no-op */},
                                }}
                                editableFxBarChecker={() => false}
                            />
                        </div>
                        <AccountTable
                            key={`${currentAccount.id}#${property.id}`}
                            year={property.budgetYear}
                            versionType={VersionType.Budget}
                            versionId={""}
                            property={property}
                            account={{
                                id: currentAccount.id,
                                accountNumber: currentAccount.number,
                                name: currentAccount.name,
                                order: currentAccount.order,
                                parentId: currentAccount.parentId ?? "",
                            }}
                            combinedData={accountViewCombinedData}
                            forecastLocks={{budgetLocked: true, reforecastLocked: true}}
                            lockOverrides={{budget: false, reforecast: false}}
                        />
                        <div className={styles.noteContainer}>
                            <h3>Note</h3>
                            <div className={styles.noteContent}>
                                {currentAccount.note ?? ""}
                            </div>
                        </div>
                    </>
                }
                </Body>
            <Close aria-label="Close modal" />
        </Modal>
    );
} 