import {ReactElement, useState, useEffect, useMemo} from "react";
import * as css from "../styles/unitTypeManagement.module.scss";
import {ListUnitTypesQuery} from "../../../__generated__/generated_types";
import {Field, MediaInput, Label, Message} from '@zendeskgarden/react-forms';
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Inline} from "@zendeskgarden/react-loaders";
import {UnitTypePMSInfo} from "../types";
import {Cell, Head, HeaderRow, SortableCell, HeaderCell, Body as TBody, Row, Table} from "@zendeskgarden/react-tables";
import {nextSortType, SortType} from "../../../utils/sorting";
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {ReactComponent as CheckIcon} from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';

type UnitTypePMSInfoForEdit = Pick<UnitTypePMSInfo, "name" | "seenEarliest"> & {
    isUsedAlready: 0 | 1;
};

export type EditUnitTypeProps = {
    existingUnitTypes: ListUnitTypesQuery["listUnitTypes"];
    pmsUnitTypes: Pick<UnitTypePMSInfo, "name" | "seenEarliest">[] | undefined,
    pmsUnitTypesAsOfDate: string | undefined,
    initialName?: string;
    unitTypeId?: string;
    title: string;
    onCancel: () => void;
    onSubmit: (
        name: string | undefined
    ) => void;
};

type SortColumn = "NAME" | "SEEN_EARLIEST" | "USED_ALREADY";
type SortField = keyof Pick<UnitTypePMSInfoForEdit, "name" | "seenEarliest" | "isUsedAlready">;

export function EditUnitType(props: EditUnitTypeProps): ReactElement {
    const [name, setName] = useState<string | undefined>(props.initialName);
    const [valid, setValid] = useState({valid: true, error: ""});
    const [inAlert, setInAlert] = useState(false);
    const [applyClicked, setApplyClicked] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [pmsUnitTypes, setPMSUnitTypes] = useState<UnitTypePMSInfoForEdit[]>();

    useEffect(() => {
        if (!props.pmsUnitTypes || props.pmsUnitTypes.length === 0) {
            setPMSUnitTypes(undefined);
            return;
        }
        const pmsUnitTypes: UnitTypePMSInfoForEdit[] = [];
        for (const pmsUnitType of props.pmsUnitTypes) {
            pmsUnitTypes.push({
                name: pmsUnitType.name,
                seenEarliest: pmsUnitType.seenEarliest,
                isUsedAlready: props.existingUnitTypes.find(eut => eut.name == pmsUnitType.name) ? 1 : 0
            });
        }
        setPMSUnitTypes(pmsUnitTypes);
    }, props.pmsUnitTypes);

    useEffect(() => {
        let valid = true;
        let error = "";
        let inAlert = false;

        if (name !== props.initialName) {
            const sameFound = props.existingUnitTypes.find(unitType =>
                unitType.id != props.unitTypeId
                && unitType.name == name
            );
            valid = sameFound == undefined;
            if (!valid) {
                error = "Same Unit Type Exists";
            }
        }

        if (props.pmsUnitTypes && props.pmsUnitTypes.length > 0 && name && name.trim().length > 0) {
            inAlert = !props.pmsUnitTypes.find(item => item.name == name);
        }

        setInAlert(inAlert);
        setValid({valid: valid, error: error});
    }, [name]);

    function handleSubmit() {
        props.onSubmit(name);
    }

    function hasChanges(): boolean {
        const ret = (name !== props.initialName);
        return ret;
    }

    function handleSortUpdate(column: SortColumn | undefined) {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortType(nextSortType(undefined));
        }
        else {
            setSortType(nextSortType(sortType));
        }
    }

    function currentSortForColumn(column: SortColumn | undefined): SortType | undefined {
        let type: SortType | undefined = undefined;
        if (!column) {
            type = undefined;
        }
        else if (sortColumn === column) {
            type = sortType;
        }

        return type;
    }

    const [sortColumn, setSortColumn] = useState<SortColumn>();
    const [sortType, setSortType] = useState<SortType>();

    const pmsUnitTypeRows = useMemo(() => {
        if (!pmsUnitTypes || pmsUnitTypes.length == 0) {
            return null;
        }
        let updated = pmsUnitTypes;
        if (searchString && searchString.trim().length > 0) {
            updated = updated.filter(item => `${item.name}`.toLowerCase().includes(searchString.toLowerCase()));
        }
        let sortField: SortField | undefined = undefined;
        let sortAscending: boolean | undefined = undefined;
        if (sortColumn && sortType) {
            if (sortColumn === "NAME") {
                sortField = "name";
            }
            else if (sortColumn === "SEEN_EARLIEST") {
                sortField = "seenEarliest";
            }
            else if (sortColumn === "USED_ALREADY") {
                sortField = "isUsedAlready";
            }
            if (sortType === "asc") {
                sortAscending = true;
            }
            else if (sortType === "desc") {
                sortAscending = false;
            }
            if (sortField !== undefined && sortAscending !== undefined) {
                if (sortAscending) {
                    updated = updated.sortBy(sortField);
                }
                else {
                    updated = updated.sortByDescending(sortField);
                }
            }
        }
        else {
            updated = updated.sortBy("name");
        }

        return updated.map(ut =>
            <Row>
                <Cell>
                    {ut.name}
                </Cell>
                <Cell>
                    {ut.seenEarliest}
                </Cell>
                <Cell>
                    {ut.isUsedAlready ? <CheckIcon /> : null}
                </Cell>
                <Cell>
                    <Button isBasic onClick={() => setName(ut.name)}>Use</Button>
                </Cell>
            </Row>
        );
    }, [pmsUnitTypes, searchString, sortColumn, sortType]);

    return (
        <Modal onClose={props.onCancel} isLarge style={{minWidth: "80rem", overflow: "unset"}}>
            <Header>
                {props.title}
            </Header>
            <Body>
                <form className={css.editForm}>
                    <Field>
                        <Label>
                            Unit Type Name
                        </Label>
                        <MediaInput value={name} onChange={e => setName(e.target.value)} />
                    </Field>
                </form>
                {pmsUnitTypes &&
                    <>
                        <Label style={{paddingTop: "1rem"}}>
                            Known Unit Type Names
                            {props.pmsUnitTypesAsOfDate &&
                                <span>&nbsp;(as of {props.pmsUnitTypesAsOfDate})</span>
                            }
                        </Label>
                        <div className={css.pmsPanel}>
                            <div className={css.pmsTableFilters}>
                                <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                            </div>
                            <div className={css.pmsTableWrapper}>
                                <Table className={css.pmsTable}>
                                    <Head isSticky className={css.pmsTableHead}>
                                        <HeaderRow>
                                            <SortableCell
                                                onClick={() => handleSortUpdate("NAME")}
                                                sort={currentSortForColumn("NAME")}
                                            >
                                                Unit Type Name
                                            </SortableCell>
                                            <SortableCell
                                                onClick={() => handleSortUpdate("SEEN_EARLIEST")}
                                                sort={currentSortForColumn("SEEN_EARLIEST")}
                                            >
                                                First Seen in PMS
                                            </SortableCell>
                                            <SortableCell
                                                onClick={() => handleSortUpdate("USED_ALREADY")}
                                                sort={currentSortForColumn("USED_ALREADY")}
                                            >
                                                Used Already
                                            </SortableCell>
                                            <HeaderCell></HeaderCell>
                                        </HeaderRow>
                                    </Head>
                                    <TBody>
                                        {pmsUnitTypeRows}
                                    </TBody>
                                </Table>
                            </div>
                        </div>
                    </>
                }
            </Body>
            <Footer>
                <FooterItem>
                    {!valid.valid ?
                        <Message validation="error">{valid.error}</Message>
                        : inAlert ?
                            <Message validation="warning">Unit Type not found in PMS</Message>
                            : null
                    }
                </FooterItem>
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!valid.valid || !hasChanges() || name == undefined  || name.trim().length == 0 || applyClicked}
                        onClick={() => {setApplyClicked(true); handleSubmit();}}
                    >
                        {
                            applyClicked
                                ? <Inline size={24} aria-label="loading" />
                                : <span>Apply</span>
                        }
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

