import {FinancialEntityType} from "../../__generated__/generated_types";
import {FinancialEntity} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Property} from "../../contexts/properties/PropertiesContext";
import {formatterDollarUSNoDecimal} from "../../utils/formatters";
import {TTableRow} from "./types";

export function buildDisplayRows(rows: TTableRow[], properties: Property[], collapsedRowIds: string[], selectedAccountIds: string[]): TTableRow[] {
    const updated: TTableRow[] = [];
    for (const row of rows) {
        const rowCollapsed = collapsedRowIds.includes(row.id);
        let canDisplayRow =
            // always show first level rows (no parents) and first level rows (one parent)
            row.parentIds.length == 0
            && (
                !row.isSubtotalRow
                || row.isSubtotalRow && !rowCollapsed
            )
            || (
                // show rows that have all parents expanded
                !row.parentIds.some(id => collapsedRowIds.includes(id))
                && (
                    !row.isSubtotalRow
                    || row.isSubtotalRow && !rowCollapsed
                )
            );

        canDisplayRow = canDisplayRow && selectedAccountIds.includes(row.id);

        if (canDisplayRow) {
            updated.push(row);
            // add property ids
            if (row.type === FinancialEntityType.Account
                && !row.isSubtotalRow
                && !rowCollapsed) {
                for (const property of properties) {
                    updated.push({
                        id: property.id,
                        name: property.name,
                        parentIds: [row.id, ...row.parentIds],
                        type: FinancialEntityType.Account,
                        isPropertyRow: true,
                        isSubtotalRow: false,
                        reforecastStartMonthIndex: property.reforecastStartMonthIndex,
                    });
                }
            }
        }
    }
    return updated;
}

export function buildPropertyDisplayRows(accountRow: TTableRow, properties: Property[]): TTableRow[] {
    const propertyRows: TTableRow[] = [];
    for (const property of properties) {
        propertyRows.push({
            id: property.id,
            name: property.name,
            parentIds: [accountRow.id, ...accountRow.parentIds],
            type: FinancialEntityType.Account,
            isPropertyRow: true,
            isSubtotalRow: false,
            reforecastStartMonthIndex: property.reforecastStartMonthIndex,
        });
    }

    return propertyRows;
}

export function mapFEToTableRow(e: FinancialEntity, parentIds: string[], isSubtotalRow: boolean): TTableRow {
    return {
        id: e.id,
        name: {
            name: e.name,
            number: e.number
        },
        parentIds: [...parentIds],
        type: e.type,
        isPropertyRow: false,
        isSubtotalRow: isSubtotalRow
    };
}

export function addChildren(parent: FinancialEntity, parentIdsIn: string[], result: TTableRow[]): void {
    const parentIds = [...parentIdsIn];
    if (parent.type !== FinancialEntityType.Component) {
        parentIds.unshift(parent.id);
    }
    for (const child of parent.children.sortBy("order")) {
        result.push(mapFEToTableRow(child, parentIds, false));
        addChildren(child, parentIds, result);
        result.push(mapFEToTableRow(child, parentIds, true));
    }
}

export function buildRows(chartOfAccountsTree: FinancialEntity[]): TTableRow[] {
    const rows: TTableRow[] = [];
    for (const record of chartOfAccountsTree.sortBy("order")) {
        const parentIds = [] as string[];
        addChildren(record, parentIds, rows);
        rows.push(mapFEToTableRow(record, [], false));
    }
    return rows;
}

export function mapValues(valuesIn: number[] | undefined): string[] {
    let values: string[] = new Array(28).fill(null);
    const EPSILON = 0.01;

    if (valuesIn) {
        const firstTwelveValues = valuesIn.slice(0, 12);
        const lastTwelveValues = valuesIn.slice(12, 24);
        const rfcstTotal = firstTwelveValues.sum();
        const bdgtTotal = lastTwelveValues.sum();
        let varianceAmount = null;
        let variancePercent = null;
        if (Math.abs(rfcstTotal) < EPSILON) {
            varianceAmount = Math.abs(bdgtTotal) < EPSILON ? 0 : bdgtTotal;
            variancePercent = 0;
        } else {
            varianceAmount = bdgtTotal - rfcstTotal;
            variancePercent = parseFloat(((bdgtTotal / rfcstTotal - 1) * 100).toFixed(1));
        }

        valuesIn = [
            ...firstTwelveValues,
            ...lastTwelveValues,
            rfcstTotal,
            bdgtTotal,
            varianceAmount,
        ];
        values = valuesIn.map(v => formatterDollarUSNoDecimal.format(v));
        values.push(variancePercent.toString() + "%");
    } else {
        values = new Array(28).fill(null);
    }

    return values;
}
