import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {ReactElement} from "react";
import {INumbersTable, NumbersTable} from "./NumbersTable";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {TTableRow} from "./types";
import {formatterGlName} from "../../utils/formatters";

export type IAccountPropertiesModal = {
    onClose: () => void;
} & INumbersTable
& {
    glTotalRow: TTableRow;
};

export function AccountPropertiesModal(props: IAccountPropertiesModal): ReactElement | null {
    const nameComponents = props.glTotalRow.name as {name: string, number: string};
    const glName = formatterGlName(nameComponents.name, nameComponents.number);
    return (
        <Modal
            onClose={() => props.onClose()}
            isLarge
            // NOTE: keep isAnimated false to prevent NumbersTable component from using not fully opened modal width and height
            isAnimated={false}
            style={{overflow: "unset", width: "calc(100% - 1rem)", minHeight: "calc(100% - 5rem)", height: "calc(100% - 5rem)"}}
        >
            <Header>
                {glName} by Property
            </Header>
            <Body>
                <div className={css.wrapper} style={{padding: 0, margin: 0, height: "100%"}}>
                    <div className={css.tableWrapper}>
                        <div className={css.tableOuter}>
                            <NumbersTable
                                {...props}
                                firstColumnLabel="Property Name"
                                disableIndentPropertyName
                            />
                        </div>
                    </div>
                </div>
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    );
}