import { ReactElement, useState, useEffect, useMemo } from "react";
import * as css from "../styles/coaManagement.module.scss";
import { FinancialEntityType } from "../../../__generated__/generated_types";
import { Field, MediaInput, Radio, Label, Fieldset, Message } from '@zendeskgarden/react-forms';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { ItemData, PMSAccount } from "../types";
import {Cell, Head, HeaderCell, HeaderRow, Row, SortableCell, Table, Body as TBody} from "@zendeskgarden/react-tables";
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {SortType, nextSortType} from "../../../utils/sorting";

export type EditRecordProps = {
    existingItems: ItemData[];
    pmsAccounts: PMSAccount[];
    initialGlNumber?: string;
    initialGlName?: string;
    itemId?: string;
    initialType: FinancialEntityType;
    allowChooseType: boolean;
    title: string;
    onCancel: () => void;
    onSubmit: (glNumber: string | undefined, glName: string, type: FinancialEntityType) => void;
}

type SortColumn = "GL_NAME" | "GL_NUMBER" | "SEEN_EARLIEST";
type SortField = keyof Pick<PMSAccount, "glName"|"glNumber"|"seenEarliest">;

export function EditRecord(props: EditRecordProps): ReactElement {
    const [glNumber, setGlNumber] = useState<string | undefined>(props.initialGlNumber);
    const [glName, setGlName] = useState<string | undefined>(props.initialGlName);
    const [valid, setValid] = useState({valid: true, error: ""});
    const [inAlert, setInAlert] = useState(false);
    const [type, setType] = useState<FinancialEntityType>(props.initialType);

    const [searchString, setSearchString] = useState<string>();
    const [pmsAccounts, setPMSAccounts] = useState<PMSAccount[]>([]);
    const [pmsAccountsLatest, setPMSAccountsLatest] = useState<string>();

    useEffect(() => {
        let valid = true;
        let error = "";
        let inAlert = false;

        if (glNumber !== props.initialGlNumber || glName !== props.initialGlName) {
            if (type == "ACCOUNT" && (!glNumber || glNumber.trim() == "")) {
                valid = false;
                error = "Account must have number";
            }
            else {
                const sameFound = props.existingItems.filter(item => !item.isDeleted).find(item =>
                    item.id != props.itemId
                    &&
                    (
                        item.type == type
                        && item.glName == glName
                        && (
                            !item.glNumber && (!glNumber || glNumber == "")
                            || item.glNumber == glNumber
                        )
                        ||
                        item.glNumber == glNumber && item.glNumber && item.glNumber != ""
                    )
                );
                valid = sameFound == undefined;
                if (!valid) {
                    error = "Same Record Exists";
                }
            }
        }

        if (type == "ACCOUNT" && pmsAccounts.length > 0 && pmsAccountsLatest) {
            inAlert = !pmsAccounts.find(item => item.glName == glName && item.glNumber == glNumber);
        }

        setInAlert(inAlert);
        setValid({valid: valid, error: error});
    }, [glName, glNumber, type, pmsAccounts]);

    useEffect(() => {
        const latestDate = props.pmsAccounts.maxValue(item => item.seenLatest);
        const latestAccounts = props.pmsAccounts.filter(item => item.seenLatest == latestDate);
        setPMSAccountsLatest(latestDate);
        setPMSAccounts(latestAccounts);
    }, []);

    function handleUsePMSAccount(account: PMSAccount) {
        setGlName(account.glName);
        setGlNumber(account.glNumber);
    }

    const [sortColumn, setSortColumn] = useState<SortColumn>();
    const [sortType, setSortType] = useState<SortType>();

    function handleSortUpdate(column: SortColumn | undefined) {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortType(nextSortType(undefined));
        }
        else {
            setSortType(nextSortType(sortType));
        }
    }

    function currentSortForColumn(column: SortColumn | undefined): SortType | undefined {
        let type: SortType | undefined = undefined;
        if (!column) {
            type = undefined;
        }
        else if (sortColumn === column) {
            type = sortType;
        }

        return type;
    }

    const pmsAccountRows = useMemo(() => {
        let updated = pmsAccounts;
        if (searchString && searchString.trim().length > 0) {
            updated = updated.filter(item => `${item.glNumber}${item.glName}`.toLowerCase().includes(searchString.toLowerCase()));
        }
        let sortField:SortField|undefined = undefined;
        let sortAscending:boolean|undefined = undefined;
        if (sortColumn && sortType) {
            if (sortColumn === "GL_NAME") {
                sortField = "glName";
            }
            else if (sortColumn === "GL_NUMBER") {
                sortField = "glNumber";
            }
            else if (sortColumn === "SEEN_EARLIEST") {
               sortField = "seenEarliest";
            }
            if (sortType === "asc") {
                sortAscending = true;
            }
            else if (sortType === "desc") {
                sortAscending = false;
            }
            if (sortField !== undefined && sortAscending !== undefined) {
                if (sortAscending) {
                    updated = updated.sortBy(sortField);
                }
                else {
                    updated = updated.sortByDescending(sortField);
                }
            }
        }
        else {
            updated = updated.sortBy("order");
        }

        return updated.map(acc =>
            <Row>
                <Cell>
                    {acc.glNumber}
                </Cell>
                <Cell>
                    {acc.glName}
                </Cell>
                <Cell>
                    {acc.seenEarliest}
                </Cell>
                <Cell>
                    <Button isBasic onClick={() => handleUsePMSAccount(acc)}>Use</Button>
                </Cell>
            </Row>
        );
    },[pmsAccounts, pmsAccountsLatest, searchString, sortColumn, sortType]);

    return (
        <Modal onClose={props.onCancel} isLarge className={css.editModal}>
            <Header>
                {props.title}
            </Header>
            <Body className={css.editModalBody}>
                <form className={css.editForm}>
                    <Field>
                        <Label>Account Number</Label>
                        <MediaInput value={glNumber} onChange={e => setGlNumber(e.target.value)} />
                    </Field>
                    <Field>
                        <Label>Account Name</Label>
                        <MediaInput value={glName} onChange={e => setGlName(e.target.value)} />
                    </Field>
                    {props.allowChooseType &&
                    <Fieldset>
                        <Label isRegular={false}>Choose Type</Label>
                        <Field>
                            <Radio
                                value={FinancialEntityType.Account}
                                checked={type == FinancialEntityType.Account}
                                onChange={() => setType(FinancialEntityType.Account)}
                                disabled={!props.allowChooseType}
                            >
                                <Label isRegular>{FinancialEntityType.Account.toPascalCase()}</Label>
                            </Radio>
                        </Field>
                        <Field>
                            <Radio
                                value={FinancialEntityType.Category}
                                checked={type == FinancialEntityType.Category}
                                onChange={() => setType(FinancialEntityType.Category)}
                                disabled={!props.allowChooseType}
                            >
                                <Label isRegular>{FinancialEntityType.Category.toPascalCase()}</Label>
                            </Radio>
                        </Field>
                    </Fieldset>
                    }
                </form>
                {type === FinancialEntityType.Account && pmsAccounts.length > 0 &&
                <div className={css.pmsPanel}>
                    <div className={css.pmsTableFilters}>
                        <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                        {pmsAccountsLatest &&
                        <span>As of {pmsAccountsLatest}</span>
                        }
                    </div>
                    <div className={css.pmsTableWrapper}>
                        <Table className={css.pmsTable}>
                            <Head isSticky className={css.pmsTableHead}>
                                <HeaderRow>
                                    <SortableCell
                                        onClick={() => handleSortUpdate("GL_NUMBER")}
                                        sort={currentSortForColumn("GL_NUMBER")}
                                    >
                                        Account Number
                                    </SortableCell>
                                    <SortableCell
                                        onClick={() => handleSortUpdate("GL_NAME")}
                                        sort={currentSortForColumn("GL_NAME")}
                                    >
                                        Account Name
                                    </SortableCell>
                                    <SortableCell
                                        onClick={() => handleSortUpdate("SEEN_EARLIEST")}
                                        sort={currentSortForColumn("SEEN_EARLIEST")}
                                    >
                                        First Seen in PMS
                                    </SortableCell>
                                    <HeaderCell></HeaderCell>
                                </HeaderRow>
                            </Head>
                            <TBody>
                                {pmsAccountRows}
                            </TBody>
                        </Table>
                    </div>
                </div>
                }
            </Body>
            <Footer>
                <FooterItem>
                    {!valid.valid ?
                    <Message validation="error">{valid.error}</Message>
                    : inAlert ?
                    <Message validation="warning">Account not found in PMS</Message>
                    : null
                    }
                </FooterItem>
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!valid.valid || (glNumber == props.initialGlNumber && glName == props.initialGlName && type == props.initialType)}
                        onClick={() => { if (glName) props.onSubmit(glNumber, glName, type) }}
                    >
                        Apply
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

