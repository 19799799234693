export type SortType = "asc" | "desc";
export function nextSortType(sortType: SortType | undefined): SortType | undefined {
    if (sortType === undefined) {
        return "asc";
    }
    if (sortType === "asc") {
        return "desc";
    }
    if (sortType === "desc") {
        return undefined;
    }
}