import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ChangePassword from '../../components/auth/ChangePassword';
import NavBar from '../../components/navbar/NavBar';
import AdminStart from '../../pages/admin/admin-start/AdminStart';
import Dashboard from '../../pages/dashboard/Dashboard';
import StartPlanning from '../../pages/start-planning/StartPlanning';
import {useRoutingUtils} from '../RoutingUtils';
import {AdminRoute} from "../AdminRoute";
import AnalystDashboard from "../../pages/analyst/AnalystDashboard";
import {AccountRoute, AccountRouteParentComponents} from "./account/AccountRoute";
import UnitLevelModeling from "../../pages/unit-level-modeling/UnitLevelModeling";
import {FileUploadCreate} from "../../pages/admin/file-upload/FileUploadCreate";
import ReportsRoute from "../../pages/reports/ReportsRoute";
import {LineItemsReport} from '../../pages/reports/line-items-report/LineItemsReport';
import {FeatureFlagContext} from '../../feature-flag/FeatureFlagContextProvider';
import {Feature} from '../../feature-flag/Feature';
import PlanningDashboard from "../../pages/planning-dashboard/PlanningDashboard";
import PropertyDrivers from "../../pages/property-drivers/PropertyDrivers";
import TopCardTagSettings from '../../pages/admin/top-card-tag-settings/TopCardTagSettings';
import {AuthContext} from '../../contexts/AuthContext';
import FinancialReportsBulkExport from '../../pages/bulk-export/FinancialReportsBulkExport';
import OperationalReportsBulkExport from '../../pages/bulk-export/OperationalReportsBulkExport';
import BulkUpdate from '../../pages/bulk-update/BulkUpdate';
import BudgetSeasonManagement from "../../pages/budget-season-management/BudgetSeasonManagement";
import COAManagement from "../../pages/coa-management/COAManagement";
import UnitTypeManagement from "../../pages/unit-type-management/UnitTypeManagement";
import {COATags} from "../../pages/coa-tags/COATags";
import {CircularReferences} from "../../pages/circular-references/CircularReferences";
import {CustomItems} from "../../pages/custom-items/CustomItems";
import {MixPanelRouteWatcher} from "../../mixpanel/MixPanelRouteWatcher";
import {PropertyBudgetCompletion} from "../../pages/budget-completion/PropertyBudgetCompletion";
import {Users} from "../../pages/admin/users/Users";
import {SendBudget} from "../../pages/admin/jobs/SendBudget";
import ExecutiveSummaryReportManagement
    from "../../pages/executive-summary-report-management/ExecutiveSummaryReportManagement";
import {
    PropertyExecutiveSummaryRegularWrapper
} from "../../pages/property-executive-summary/PropertyExecutiveSummaryRegularWrapper";
import {FunPlayground} from '../../fun/FunPlayground';
import PropertyManagement from "../../pages/property-management/PropertyManagement";
import {PortfolioFinancialPage} from "../../pages/portfolio-financial/PortfolioFinancialPage";
import {checkAuthorization, userIsAdmin} from "../../authorization/AuthorizationCheckers";
import {Authorizations} from "../../authorization/Authorizations";

{/*
    TODO: Normalize to a correct top-level ThemeProvider (https://vizibly.atlassian.net/browse/APP-922), then wrap
    main route and remove ThemeProviders down in the code (unless rare cases when they're necessary)
*/}

export interface MainRouteProps {
    signedIn?: () => void
}
export const MainRoute: React.FC<MainRouteProps> = (params: MainRouteProps) =>
    <>
        <MixPanelRouteWatcher />
        <Contents {...params}></Contents>
    </>;

const Contents: React.FC<MainRouteProps> = () => {
    const {goto} = useRoutingUtils();
    const {hasFeature} = useContext(FeatureFlagContext);
    const {user} = useContext(AuthContext);

    return (
        <div className="d-flex flex-column h-100">
            <NavBar
                needSettings={goto('/admin/start')}
                needPlan={goto('/setup/start')}
            />
            <Switch>
                <Route exact path="/auth/changepassword" component={ChangePassword} />
                <AdminRoute exact path="/admin/start" component={() =>
                    <AdminStart
                        loggedOut={() => goto('/')}
                    />
                } />
                <Route exact path="/dashboard" component={() => <Dashboard />} />
                <Route exact path="/line_items" component={() => <LineItemsReport />} />
                <Route exact path="/hub/:tab?" component={AnalystDashboard} />
                <Route exact path="/property_executive_summary" component={() => <PropertyExecutiveSummaryRegularWrapper/>}/>
                <Route exact path="/portfolio_analysis" component={() => <PortfolioFinancialPage/>}/>
                <Route exact path="/fun-playground" component={() => <FunPlayground />} />
                <Route exact path="/revenue/:tab?/:type?" component={UnitLevelModeling} />
                <AdminRoute exact path="/admin/users" component={Users} />
                    <AdminRoute exact path="/admin/send_budget" component={SendBudget} />
                <AdminRoute exact path="/admin/file-upload/create" component={FileUploadCreate} />
                <Route path="/account/:id/:type">
                    <AccountRoute parentPath={AccountRouteParentComponents.ACCOUNT} />
                </Route>
                {
                    /**
                     * 1. The original and unit level models use the normal operational routes.
                     * 2. The simplified model uses the new simplified-revenue routes.
                     */
                }
                <Route path="/operational/:page/:type" component={() =>
                    <AccountRoute parentPath={AccountRouteParentComponents.OPERATIONAL} />
                } />

                <Route path="/renovations/:page/:type" component={() =>
                    <AccountRoute parentPath={AccountRouteParentComponents.RENOVATIONS} />
                } />

                <Route path="/simplified-revenue/:page" component={() =>
                    <AccountRoute parentPath={AccountRouteParentComponents.SIMPLIFIED_REVENUE} />
                } />

                <Route path="/payroll" component={() =>
                    <AccountRoute parentPath={AccountRouteParentComponents.PAYROLL} />
                } />

                <Route path="/circular-references">
                    <CircularReferences/>
                </Route>

                <Route path="/budget-completion">
                    <PropertyBudgetCompletion/>
                </Route>

                {hasFeature(Feature.MVR) &&
                    <Route path="/reports/mvr">
                        <ReportsRoute />
                    </Route>
                }

                <Route path="/bulk-export/financial-reports" component={() =>
                    <FinancialReportsBulkExport />
                } />

                <Route path="/bulk-export/operational-reports" component={() =>
                    <OperationalReportsBulkExport />
                } />

                {/* TODO remove feature check and ternary here when StartPlanning page is fully deprecated  */}
                <Route
                    exact
                    path="/setup/start"
                    component={hasFeature(Feature.PlanningDashboard) ? PlanningDashboard : StartPlanning}
                />

                {userIsAdmin(user) &&
                    <Route path="/settings/financial-metrics-management">
                        <TopCardTagSettings />
                    </Route>
                }

                {checkAuthorization(user, Authorizations.AccessPropertyPlanManagement) &&
                    <Route path="/settings/property-plan-management">
                        <PropertyDrivers />
                    </Route>
                }

                {/*userIsAdmin(user) &&
                    <Route path="/settings/bulk-assumptions">
                        <BulkAssumptions />
                    </Route>
                */}

                {userIsAdmin(user) &&
                    <Route path="/settings/lock-management">
                        <BulkUpdate />
                    </Route>
                }

                {checkAuthorization(user, Authorizations.AccessBudgetSeasonManagement) &&
                    <Route path="/settings/budget-season-management">
                        <BudgetSeasonManagement />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/coa-management">
                        <COAManagement />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/account-tags-management">
                        <COATags />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/custom-item-management">
                        <CustomItems />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/unit-type-management">
                        <UnitTypeManagement />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/property-management">
                        <PropertyManagement />
                    </Route>
                }

                {userIsAdmin(user) &&
                    <Route path="/settings/executive-summary-report-management">
                        <ExecutiveSummaryReportManagement />
                    </Route>
                }

                <Route exact path="/">
                    <Redirect to="/setup/start" />
                </Route>

                <Route path="*">
                    <Redirect to="/setup/start" />
                </Route>
            </Switch>
        </div>
    );
};
