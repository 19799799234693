import * as css from "./styles/userFormStyles.module.scss";

import { Field, Label, Toggle } from "@zendeskgarden/react-forms";
import {
    UserRole,
    useListAuthorizationsLazyQuery,
    useListGroupsQuery
} from "../../../../../__generated__/generated_types";
import { useProperties } from "../../../../../contexts/properties/PropertiesContext";
import UserValidator, { TFormError } from "../../logic/userValidator";
import UserFormDropdown from "./UserFormDropdown";
import UserFormInput from "./UserFormInput";
import { ReactElement, useEffect, useState } from "react";
import { Separator } from "@zendeskgarden/react-dropdowns";
import { Body, Footer, FooterItem, Header } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { ThemeProvider } from "styled-components";
import { ViziblyTheme } from "../../../../analyst/ViziblyZDGTheme";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../../../../constants/Colors";
import {formatterEnumToTitle} from "../../../../../utils/formatters";
import {authorizationTitleRemapping} from "../../logic/authorizationRenamer";
import { CustomZDDropdown } from "../../../../../atoms/custom-zd-dropdown/CustomZDDropdown";

export type TUserRecord = {
    id?: string;
    firstName: string;
    lastName: string;
    email?: string;
    generatePassword?: boolean;
    password?: string;
    role: UserRole;
    isReadOnly: boolean;
    authorizations: string[];
    properties: { id: string, name: string }[];
    groups: {id: string; name: string;}[];
}

export interface IUserForm {
    isCreate?: boolean,
    user: TUserRecord,
    loading: boolean,
    onClose: () => void,
    onChange: (updates: Partial<TUserRecord>) => void,
    onSubmit: () => void,
}

export default function UserForm(props: IUserForm): ReactElement {
    const {
        isCreate = false,
        user,
        loading,
        onClose,
        onChange,
        onSubmit,
    } = props;
    const {properties} = useProperties();
    const roles = Object.values(UserRole);
    const userValidator = new UserValidator();
    const [formErrors, setFormErrors] = useState<TFormError[]>([]);
    const [adminSelected, setAdminSelected] = useState<boolean>(user?.role == UserRole.Admin);

    function checkValidOnBlur(field: string): void {
        if (formErrors.find(x => x.field == field)) {
            setFormErrors(userValidator.validate(user, isCreate));
        }
    }

    function submit(): void {
        const errors = userValidator.validate(user, isCreate);

        if (errors.length == 0) {
            onSubmit();
        } else {
            setFormErrors(errors);
        }
    }

    const [loadAuthorizations, { data: authorizationsData, loading: authorizationsLoading }] = useListAuthorizationsLazyQuery({
        fetchPolicy: "network-only"
    });
    const {loading: groupsLoading, data: groups} = useListGroupsQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        loadAuthorizations();
    }, []);

    if (!authorizationsData || authorizationsLoading || !groups || groupsLoading) {
        return <></>;
    }

    const authorizationItems: {id: string, name: string}[] = [];
    for(const authorization of authorizationsData.listAuthorizations) {
        authorizationItems.push({
            id: authorization,
            name: formatterEnumToTitle(authorizationTitleRemapping(authorization))
        });
    }

    const userAuthorizations: {id: string, name:string}[] = [];
    for(const authorization of user.authorizations) {
        if(authorizationsData.listAuthorizations.includes(authorization)) {
            userAuthorizations.push({
                id: authorization,
                name: formatterEnumToTitle(authorizationTitleRemapping(authorization))
            });
        }
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Header>{isCreate ? "Add" : "Edit"} User</Header>
            <Body>
                <form className={css.userForm}>
                    <UserFormInput
                        label={"First Name"}
                        placeholder={"First Name"}
                        required
                        value={user.firstName}
                        error={formErrors.find(x => x.field == "firstName")?.message}
                        onChange={evt => onChange({ firstName: evt.target.value })}
                        onBlur={() => checkValidOnBlur('firstName')}
                    />

                    <UserFormInput
                        label={"Last Name"}
                        placeholder={"Last Name"}
                        required
                        value={user.lastName}
                        error={formErrors.find(x => x.field == "lastName")?.message}
                        onChange={evt => onChange({ lastName: evt.target.value })}
                        onBlur={() => checkValidOnBlur('lastName')}
                    />

                    {
                        isCreate &&
                        <>
                            <UserFormInput
                                label={"Email"}
                                placeholder={"Password"}
                                required
                                value={user.email}
                                error={formErrors.find(x => x.field == "email")?.message}
                                onChange={evt => onChange({ email: evt.target.value })}
                                onBlur={() => checkValidOnBlur('email')}
                            />

                            <Field>
                                <Toggle checked={user.generatePassword ?? false}
                                        onChange={evt => onChange({ generatePassword: evt.target.checked })}>
                                    <Label>Generate Password?</Label>
                                </Toggle>
                            </Field>
                            {!user.generatePassword &&
                                <UserFormInput
                                    label={"Password"}
                                    placeholder={"Password"}
                                    required
                                    value={user.password}
                                    error={formErrors.find(x => x.field == "password")?.message}
                                    onChange={evt => onChange({ password: evt.target.value })}
                                    onBlur={() => checkValidOnBlur('password')}
                                />
                            }
                        </>
                    }

                    <UserFormDropdown
                        label={"Role"}
                        required
                        selection={user.role}
                        options={roles}
                        error={formErrors.find(x => x.field == "role")?.message}
                        onSelect={item => {
                            setAdminSelected(item == UserRole.Admin);
                            onChange({ role: item });
                        }}
                        onBlur={() => checkValidOnBlur('role')}
                    />

                    {!adminSelected &&
                        <>
                            <Field>
                                <Toggle checked={user.isReadOnly}
                                    onChange={evt => onChange({ isReadOnly: evt.target.checked })}>
                                    <Label>Is Read Only?</Label>
                                </Toggle>
                            </Field>

                            <UserFormDropdown
                                    label={"Groups"}
                                    required={false}
                                    selection={user.groups}
                                    isMultiselect
                                    options={groups.listUserGroups.map(g => ({
                                        id: g.id,
                                        name: g.name
                                    }))}
                                    error={formErrors.find(x => x.field == "groups")?.message}
                                    onSelect={items => onChange({groups: items})}
                                    downshiftProps={{itemToString: (item: any) => item && item.id}}
                                    onBlur={() => checkValidOnBlur('groups')}
                            />

                            <UserFormDropdown
                                label={"Authorizations"}
                                required={false}
                                selection={userAuthorizations}
                                isMultiselect
                                options={authorizationItems}
                                error={formErrors.find(x => x.field == "authorizations")?.message}
                                onSelect={items => onChange({ authorizations: items.map((i: any) => i.id) })}
                                downshiftProps={{itemToString: (item: any) => item && item.id}}
                                onBlur={() => checkValidOnBlur('authorizations')}
                            />

                            {
                                properties &&
                                    <>
                                        <Label className={css.userFormLabel} style={{marginBottom: ".5rem"}}>
                                            <span>Properties</span>
                                            <span className={css.required}>REQUIRED</span>
                                        </Label>
                                        <CustomZDDropdown
                                            initialSelectedItems={user.properties.map(x => x.id)}
                                            applySelectedItems={function (items: string[]): void {
                                                const activeProperties = properties.filter(x => items.includes(x.id));
                                                onChange({ properties: activeProperties });
                                            }}
                                            openDropdownPlaceholder={"Properties"}
                                            closedDropdownPlaceholder={"Properties"}
                                            options={properties.map(p => ({value: p.id, label: p.name}))}
                                            isError={!!formErrors.find(x => x.field == "properties")}
                                            isMulti
                                            allOption
                                            errorMessage="At least one property is required."
                                        />
                                    </>
                            }
                        </>
                    }
                </form>
            </Body>
            <Separator />
            <Footer>
                <FooterItem>
                    <Button onClick={onClose} isBasic disabled={loading}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary onClick={submit} disabled={loading}>
                        {
                            loading
                                ? <Inline size={24} color={COLORS.POSITIVE_COLOR} aria-label="loading"/>
                                : <span>Save</span>
                        }
                    </Button>
                </FooterItem>
            </Footer>
        </ThemeProvider>
    );

}