import React, {useContext, useEffect, useState} from 'react';
import * as cognito from '../libs/cognito';
import {
    MyUser,
    OperationalMetricType,
    Property,
    RevenueType,
    useMyUserLazyQuery,
    useOperationalMetricTypeLazyQuery,
    useRevenueTypesLazyQuery
} from "../__generated__/generated_types";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuth {
    attrInfo?: any,
    user: AuthUser | null,
    authStatus?: AuthStatus
    signInWithEmail?: any
    signInWithPasswordReset?: (user: string, oldPassword: string, newPassword: string) => Promise<void>;
    signUpWithEmail?: any
    signOut?: any
    verifyCode?: any
    getSession?: any
    sendCodeForgotPassword?: any
    changePassword?: any
    getAttributes?: any
    setAttribute?: any;

    setPasswordAndLogin: (username: string, code: string, password: string) => Promise<void>
    username?: string;
    setUsername: (username: string) => void;
    resendCode: () => void;
    reloadUserWithProperties: () => void;

    operationalMetricTypes: Pick<OperationalMetricType, 'id' | 'name'>[];
    revenueTypes: RevenueType[];
}

export type AuthUser = Omit<MyUser, "properties"> & {properties: Pick<Property, "id" | "name" | "unitLevelModelingEnabled" | "address" | "externalId" | "fiscalYearStartMonthIndex" | "reforecastStartMonthIndex" | "reforecastYear" | "revenueModel" | "originalRevenueMarketRentModeType" | "propertyManagementSystem" | "displayName">[]};

const defaultState: IAuth = {
    authStatus: AuthStatus.Loading,
    operationalMetricTypes: [],
    revenueTypes: [],
    user: null,
    setPasswordAndLogin: async () => {/* Do nothing */},
    setUsername: () => {/* */},
    resendCode: () => {/* */},
    reloadUserWithProperties: () => {/* */}
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn: React.FunctionComponent = ({ children }) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent = ({ children }) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [attrInfo, setAttrInfo] = useState([]);
    const [username, setUsername] = useState<string>();
    const [user, setUser] = useState<AuthUser | null>(null);
    const [operationalMetricTypes, setOperationalMetricTypes] = useState<Pick<OperationalMetricType, 'id' | 'name'>[]>([]);
    const [revenueTypes, setRevenueTypes] = useState<RevenueType[]>([]);

    const [getMyUser, {data: myUserResult, loading: myUserLoading, error: myUserError}] = useMyUserLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getOperationalMetricType, {data: operationalMetricTypeResult, loading: operationalMetricTypeLoading, error: operationalMetricTypeError}] = useOperationalMetricTypeLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getRevenueTypes, {data: revenueTypesResult, loading: revenueTypesLoading, error: revenueTypesError}] = useRevenueTypesLazyQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if(!myUserLoading && myUserResult && myUserResult.myUser) {
            const user = myUserResult.myUser;
            const userWithFeatures: AuthUser = {
                ...user,
                features: []
            };
            setUser(userWithFeatures);
            setAuthStatus(AuthStatus.SignedIn);
        }
    }, [myUserResult]);

    useEffect(() => {
        if(!myUserLoading && myUserError) {
            setAuthStatus(AuthStatus.SignedOut);
        }
    }, [myUserError]);

    useEffect(() => {
        setOperationalMetricTypes(operationalMetricTypeResult?.operationalMetricTypes as (Pick<OperationalMetricType, 'id' | 'name'>[]));
    }, [operationalMetricTypeResult?.operationalMetricTypes]);

    useEffect(() => {
        if (revenueTypesResult?.revenueTypes === undefined) {
            return;
        }

        /**
         * Note: Type-casting because the backend simply packs the RevenueType enum keys
         * into a list of strings and sends that to the frontend, so string[] can be cast to
         * RevenueType[]
        */
        setRevenueTypes(revenueTypesResult?.revenueTypes as RevenueType[]);
    }, [revenueTypesResult?.revenueTypes]);

    useEffect(() => {
        async function getSessionInfo() {
            const idToken: string | undefined = await cognito.refreshIdToken();

            if (idToken) {
                const attr: any = await getAttributes();
                setAttrInfo(attr);
                getMyUser();
                getOperationalMetricType();
                getRevenueTypes();
            } else {
                setAuthStatus(AuthStatus.SignedOut);
            }
        }
        if(authStatus === AuthStatus.Loading) {
            getSessionInfo().catch((err) => {
                console.log("[AuthContext] failed to obtain idToken, ", err);
                setAuthStatus(AuthStatus.SignedOut);
            });
        }
    }, [authStatus]);

    if (authStatus === AuthStatus.Loading) {
        return null;
    }

    function reloadUserWithProperties() {
        getMyUser();
    }

    async function signInWithEmail(username: string, password: string) {
        try {
            await cognito.signInWithEmail(username, password);
            setAuthStatus(AuthStatus.Loading);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }

    async function signInWithPasswordReset(username: string, oldPassword: string, newPassword: string) {
        try {
            await cognito.signInWithPasswordReset(username, oldPassword, newPassword);
            setAuthStatus(AuthStatus.Loading);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }

    async function signUpWithEmail(username: string, email: string, password: string) {
        await cognito.signUpUserWithEmail(username, email, password);
    }

    function signOut() {
        cognito.signOut();
        setAuthStatus(AuthStatus.SignedOut);
    }

    async function verifyCode(username: string, code: string) {
        cognito.verifyCode( username, code );
    }

    // async function getSession() {
    //     const session = await cognito.getSession();
    //     return session;
    // }

    async function getAttributes() {
        const attr = await cognito.getAttributes();
        return attr;
    }

    // async function setAttribute(attr: any) {
    //     const res = await cognito.setAttribute(attr);
    //     return res;
    // }

    async function sendCodeForgotPassword(username: string) {
        await cognito.sendCodeForgotPassword(username);
    }

    async function setPasswordAndLogin(username: string, code: string, password: string): Promise<void> {
        await cognito.confirmPassword(username, code, password);
        await signInWithEmail(username, password);
    }

    async function changePassword(oldPassword: string, newPassword: string) {
        await cognito.changePassword(oldPassword, newPassword);
    }

    async function resendCode() {
        if (!username) {
            throw Error("username undefined");
        }
        await cognito.resendCode(username);
    }

    const state: IAuth = {
        authStatus,
        user,
        attrInfo,
        signUpWithEmail,
        signInWithEmail,
        signInWithPasswordReset,
        signOut,
        verifyCode,
        // getSession,
        sendCodeForgotPassword,
        setPasswordAndLogin,
        changePassword,
        // getAttributes,
        // setAttribute,
        username,
        setUsername,
        resendCode,
        operationalMetricTypes,
        revenueTypes,
        reloadUserWithProperties
    };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
