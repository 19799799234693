/* eslint-disable @typescript-eslint/no-empty-function */
import {ReactElement, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TabPanel, Tabs} from '@zendeskgarden/react-tabs';
import {ThemeProvider} from "styled-components";
import {BudgetingType} from "../../../BudgetingType";
import "./Account.scss";
import {AccountNote} from "../../../components/note/AccountNote";
import {AccountInstruction} from "../../../components/instruction/AccountInstruction";
import {useConfig} from "../../../hooks/useConfig";
import {Account as CoaAccount, CategoryDescriptor} from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import * as css from "./styles/styles.module.scss";
import * as workflowCSS from "../../../styles/workflows/workflowCSS.module.scss";
import StarredAccount from "../../../components/starred-account/StarredAccount";
import {ViziblyTheme} from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import WorkflowHeader from "../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowPageLabel from "../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../components/workflows/workflow-step-nav/WorkflowStepNav";
import {
    ForecastLocks,
    GetSinglePropertyDriversAndWorksheetItemsQuery,
    useGetFinancialValuesForYearLazyQuery,
    useGetForecastLocksLazyQuery,
    useGetSinglePropertyDriversAndWorksheetItemsLazyQuery,
    useSetAccountLockMutation,
    useSetDriversMutation,
    VersionType
} from "../../../__generated__/generated_types";
import * as workflowHeaderCSS from "../../../components/workflows/workflow-header/styles/css.module.scss";
import WorkflowYearTabs, {
    WORKFLOW_YEAR_TAB_BUDGET,
    WORKFLOW_YEAR_TAB_REFORECAST
} from "../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {IWorkflowPageProps} from "../logic/workflows";
import WorkflowNavToggle from "../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import {AccountSummary} from "../../../components/account-summary/AccountSummary";
import {AccountSummaryContextProvider, IAccountSummaryData} from "./AccountSummaryContext";
import FormulaBar from "./formula-bar/FormulaBar";
import YearMonthRange from "../../../components/year-month-range/YearMonthRange";
import {useAccountContext} from "../../../contexts/account/AccountContext";
import {FeatureFlagContext} from "../../../feature-flag/FeatureFlagContextProvider";
import {Feature} from "../../../feature-flag/Feature";
import {AuthContext} from "../../../contexts/AuthContext";
import AccountTable from "../../../components/account-table/AccountTable";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {buildYearSummary} from "../../../components/account-table/logic/AccountTableHelpers";
import {hydrateDriverAssumptions, TDriverMetrics} from "../../../contexts/account/data/logic/driversAndWorksheetData";
import {ISaveData} from "../../../contexts/account/data/useDriversAndWorksheetData";
import { Dropdown, HeaderItem, Item, ItemMeta, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as VerticalKebabIcon } from '@zendeskgarden/svg-icons/src/16/overflow-vertical-fill.svg';
import { IconButton } from "@zendeskgarden/react-buttons";
import { Authorizations } from "../../../authorization/Authorizations";
import { toast } from "react-toastify";
import LockBanner from "./LockBanner";
import { buildEditableFxBarChecker, canOverrideAssumptionLock, checkAuthorization, TLockOverrides } from "../../../authorization/AuthorizationCheckers";
import {formatterGlName} from "../../../utils/formatters";
import cn from "classnames";


function Account(props: IWorkflowPageProps): ReactElement {
    const config = useConfig();
    // FIXME: These are url params, so we can't guarantee accountId will be present or valid.
    const {id: accountId, type} = useParams() as { id: string; type: string };
    const {user} = useContext(AuthContext);
    const {hasFeature} = useContext(FeatureFlagContext);
    const [account, setAccount] = useState<CoaAccount | undefined>(undefined);
    const [parentCategories, setParentCategories] = useState<CategoryDescriptor[]>([]);
    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_REFORECAST);
    const year = BudgetingType.REFORECAST == type ? config.year : (config.year + 1);
    const versionType = BudgetingType.REFORECAST == type ? VersionType.Reforecast : VersionType.Budget;
    const [parsedGraphFinancialData, setParsedGraphFinancialData] = useState<IAccountSummaryData>({years: {}});
    const [combinedData, setCombinedData] = useState<{
        driversAndWorksheetData: GetSinglePropertyDriversAndWorksheetItemsQuery,
        financialYearValues: IAccountSummaryData,
    }>();
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks | undefined>();
    const [lockOverrides, setLockOverrides] = useState<TLockOverrides | undefined>();
    const [lockValuesForMenu, setLockValuesForMenu] = useState<string[]>([]);

    const ctx = useAccountContext();

    const [getFinancialValues, {data: financialValues, loading: financialValuesLoading}] = useGetFinancialValuesForYearLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });
    const [getDriverAndWorksheetData, {data: rawData,loading: dataLoading}] = useGetSinglePropertyDriversAndWorksheetItemsLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });
    const [setDrivers, {data: setDriversData}] = useSetDriversMutation();
    const [getForecastLocks, {data: dataForecastLocks, loading: forecastLocksLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [setAccountLock, {loading: accountLockSaving}] = useSetAccountLockMutation({
        onCompleted: () => {
            toast.success("Lock Updated");
        }
    });

    const saveData = (args: ISaveData): void => {
        setDrivers({
            variables: {
                accountId: args.accountId,
                propertyId: args.propertyId,
                year: args.year,
                versionType: args.versionType,
                isUserRequestingWorksheetDeletion: args.isUserRequestingWorksheetDeletion,
                pendingUpdates: args.pendingUpdates,
            },
        });
    };

    useEffect(() => {
        if (!user || !dataForecastLocks || !dataForecastLocks.forecastLocks || forecastLocksLoading) {
            return;
        }

        let reforecastLocked = false;
        let budgetLocked = false;
        let lockOverrides;
        const lockStrings = [];

        if (dataForecastLocks.forecastLocks.account?.reforecastLocked) {
            reforecastLocked = true;
            lockStrings.push("reforecastLocked");
        }

        if (dataForecastLocks.forecastLocks.account?.budgetLocked) {
            budgetLocked = true;
            lockStrings.push("budgetLocked");
        }

        if (dataForecastLocks.forecastLocks.property?.reforecastLocked) {
            reforecastLocked = true;
        }

        if (dataForecastLocks.forecastLocks.property?.budgetLocked) {
            budgetLocked = true;
        }

        if (reforecastLocked || budgetLocked) {
            lockOverrides = canOverrideAssumptionLock(user, dataForecastLocks.forecastLocks);
            setLockOverrides(lockOverrides);
        } else {
            setLockOverrides(undefined);
        }

        setForecastLocks({
            reforecastLocked,
            budgetLocked,
        });

        setLockValuesForMenu(lockStrings);

    }, [dataForecastLocks, dataForecastLocks?.forecastLocks, user, forecastLocksLoading]);

    const [parsedData, setParsedData] = useState<TDriverMetrics>();
    useEffect(
        () => {
            if(dataLoading || rawData === undefined){
                return;
            }

            setParsedData( prevState => {
                return {
                    ...prevState ?? {},
                    ...hydrateDriverAssumptions(rawData),
                };
            });

        },
        [dataLoading, rawData]
    );

    function fetchItAll() {
        if (!config.properties.currentProperty) {
            return;
        }

        let years;
        if (versionType == VersionType.Reforecast) {
            years = [
                {year: year, versionType: VersionType.Budget},
                {year: year, versionType: VersionType.Reforecast},
                {year: year, versionType: VersionType.Actuals},
                {year: year - 1, versionType: VersionType.Actuals},
                {year: year - 2, versionType: VersionType.Actuals},
                {year: year - 3, versionType: VersionType.Actuals},
            ];
        } else {
            years = [
                {year: year, versionType: VersionType.Budget},
                {year: year - 1, versionType: VersionType.Reforecast},
                {year: year - 1, versionType: VersionType.Actuals},
                {year: year - 2, versionType: VersionType.Actuals},
                {year: year - 3, versionType: VersionType.Actuals},
                {year: year - 4, versionType: VersionType.Actuals},
            ];
        }

        getDriverAndWorksheetData({variables: {propertyId: config.properties?.currentProperty?.id, accountId: accountId, year: year, versionType: versionType}});
        getFinancialValues({
            variables: {
                propertyIds: [config.properties.currentProperty.id],
                entityIds: accountId,
                years,
            }
        });
    }

    /**
     * Re-fetch drivers after setDrivers() executes successfully
     */
    useEffect(() => {
        if (setDriversData === undefined || setDriversData === null) {
            return;
        }

        fetchItAll();
    }, [setDriversData]);

    function updateGraphs(newFinancialData: IAccountSummaryData): void {
        const newData: IAccountSummaryData = {
            years: {
                ...parsedGraphFinancialData.years,
                ...newFinancialData.years,
            }
        };
        setParsedGraphFinancialData(newData);
    }

    useEffect(
        () => {
            if (!config.isReady || accountId === undefined || config.properties.currentProperty === undefined) {
                return;
            }

            // Update Account context w/ the account and property we're viewing
            ctx.setAccountId(accountId);
            ctx.setPropertyId(config.properties.currentProperty.id);

            getForecastLocks({
                variables: {
                    propertyId: config.properties.currentProperty.id,
                    budgetYear: type == BudgetingType.REFORECAST ? year + 1 : year,
                    accountId: accountId,
                }
            });

            if (type) {
                config.versions.setSelectedVersionType(type == BudgetingType.REFORECAST ? VersionType.Reforecast : VersionType.Budget);
            }
        },
        [config.isReady, accountId, type, config.properties]
    );

    useEffect(
        () => {
            if (!ctx.isReady || !config.isReady || !accountId || !config.properties.currentProperty || !versionType) {
                return;
            }

            fetchItAll();
        },
        [ctx.isReady, config.isReady, config.properties.currentProperty, accountId, versionType]
    );

    useEffect(() => {
        if (dataLoading || financialValuesLoading || !rawData || !financialValues) {
            return;
        }

        const ctxData = buildYearSummary(financialValues.financialValuesForYear, config.startReforecastMonthIndex);
        setParsedGraphFinancialData(ctxData);
        setCombinedData({driversAndWorksheetData: rawData, financialYearValues: ctxData});
    }, [financialValuesLoading, dataLoading, rawData, financialValues]);

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
        () => {
            if (type == BudgetingType.REFORECAST && selectedTab != WORKFLOW_YEAR_TAB_REFORECAST) {
                setSelectedTab(WORKFLOW_YEAR_TAB_REFORECAST);
                config.versions.setSelectedVersionType(VersionType.Reforecast);
            }
            if (type == BudgetingType.BUDGET && selectedTab != WORKFLOW_YEAR_TAB_BUDGET) {
                setSelectedTab(WORKFLOW_YEAR_TAB_BUDGET);
                config.versions.setSelectedVersionType(VersionType.Budget);
            }
        },
        [type]
    );

    useEffect(() => {
        if (config.isReady) {
            const account = config.chartOfAccountsConfig?.accounts?.find(acc => acc.id == accountId);
            const parentCategories = config.chartOfAccountsConfig.getParentCategories(account?.parentId ?? "");
            setAccount(account);
            setParentCategories(parentCategories);
        }
    }, [config.isReady, config.chartOfAccountsConfig, config.properties?.currentProperty?.id, accountId]);

    const isReforecast = type === BudgetingType.REFORECAST;
    const modelVersions = config.versions.getModelVersions(config.year);
    const versionId = isReforecast ? modelVersions.reforecastVersionId : modelVersions.nextYearBudgetVersionId;

    /**
     * Fx bar is readonly regardless or roles and permissions when either:
     * 1. The EditableFormulaBar feature is turned OFF, or
     * 2. The current forecast period is locked for the whole property
     */
    const isFxBarReadOnly = !hasFeature(Feature.EditableFormulaBar)
        || (versionType == VersionType.Reforecast && (dataForecastLocks?.forecastLocks?.property.reforecastLocked === true))
        || (versionType == VersionType.Budget && (dataForecastLocks?.forecastLocks?.property.budgetLocked === true));

    const accountLockedForVersionType = versionType === VersionType.Reforecast
        ? dataForecastLocks?.forecastLocks?.account?.reforecastLocked
        : dataForecastLocks?.forecastLocks?.account?.budgetLocked;

    function togglePeriodLock(period: string | null) {
        if (period == null || !config.properties.currentProperty || accountLockSaving) {
            return;
        }

        const propertyId = config.properties.currentProperty.id;

        let locked = false;

        if (period == "reforecastLocked") {
            locked = !lockValuesForMenu.includes("reforecastLocked");
        } else if (period == "budgetLocked") {
            locked = !lockValuesForMenu.includes("budgetLocked");
        }

        setAccountLock({
            variables: {
                propertyId,
                accountId,
                budgetYear: versionType == VersionType.Reforecast ? year + 1 : year,
                versionType: period == "reforecastLocked" ? VersionType.Reforecast : VersionType.Budget,
                locked,
            }
        }).then(() => {
            getForecastLocks({
                variables: {
                    propertyId,
                    budgetYear: versionType == VersionType.Reforecast ? year + 1 : year,
                    accountId,
                }
            });
        });
    }

    function showLockBanner(): boolean {
        if (!dataForecastLocks?.forecastLocks) {
            return false;
        }

        if (versionType == VersionType.Reforecast) {
            return (dataForecastLocks?.forecastLocks.account?.reforecastLocked && !lockOverrides?.reforecast && !dataForecastLocks?.forecastLocks.property.reforecastLocked) ?? false;
        } else if (versionType == VersionType.Budget) {
            return (dataForecastLocks?.forecastLocks.account?.budgetLocked && !lockOverrides?.budget && !dataForecastLocks?.forecastLocks.property.budgetLocked) ?? false;
        } else {
            return false;
        }
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={workflowCSS.workflowContainer}>
                <div className={workflowCSS.workflowContainerInner}>
                    <WorkflowHeader>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle />
                                <span className={workflowCSS.starredAccount}>
                                    <StarredAccount
                                        accountId={accountId}
                                        versionIds={[modelVersions.reforecastVersionId, modelVersions.nextYearBudgetVersionId]}
                                        currentVersionId={versionId}
                                    />
                                </span>
                                <WorkflowPageLabel label={account ? formatterGlName(account.name, account.accountNumber) : 'Account'}/>
                                {checkAuthorization(user, Authorizations.EditLockUnlockAccounts) &&
                                    <Dropdown
                                        selectedItems={lockValuesForMenu}
                                        onSelect={(_selections, itemClicked) => togglePeriodLock(itemClicked.inputValue)}
                                    >
                                        <Trigger>
                                            <IconButton aria-label="plant">
                                                <VerticalKebabIcon />
                                            </IconButton>
                                        </Trigger>
                                        <Menu className={css.lockMenu}>
                                            <HeaderItem className={css.lockMenuHeaderText}>
                                                GL ACCOUNT LOCKING OPTIONS
                                            </HeaderItem>
                                            <Item
                                                value="reforecastLocked"
                                            >
                                                Lock GL for {versionType == VersionType.Reforecast ? year : year - 1} Reforecast
                                            </Item>
                                            <Item
                                                value="budgetLocked"
                                            >
                                                Lock GL for {versionType == VersionType.Reforecast ? year + 1 : year} Budget
                                            </Item>
                                        </Menu>
                                    </Dropdown>
                                }
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick} />
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                    <div className={workflowCSS.bodyContainer}>
                        <AccountSummaryContextProvider>
                            <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.tabsContainer}>
                                <WorkflowYearTabs
                                    year={config.year}
                                    selectedTab={selectedTab}
                                    rawForecastLocks={dataForecastLocks}
                                    forecastLocks={forecastLocks}
                                    lockOverrides={lockOverrides}
                                />
                                {showLockBanner() &&
                                    <LockBanner text={`This GL account has been locked for ${year}. No edits are permitted`} />
                                }
                                <TabPanel item={WORKFLOW_YEAR_TAB_REFORECAST} className={workflowCSS.tabContent}>
                                    <div className={workflowCSS.dataColumn}>
                                        {
                                            user && config.isReady && hasFeature(Feature.AccountFormulaBar) && (accountLockedForVersionType !== undefined)
                                                ? <div className={css.accountFormulaBar}>
                                                    <YearMonthRange
                                                        startYear={config.year}
                                                        startMonthIdx={config.startReforecastMonthIndex}
                                                        endYear={config.year}
                                                        endMonthIdx={11}
                                                        className={css.formulaDateRange}
                                                    />
                                                    <FormulaBar
                                                        budgetYear={config.year + 1}
                                                        year={config.year}
                                                        versionType={VersionType.Reforecast}
                                                        currentPropertyId={config.properties.currentProperty?.id}
                                                        accountId={accountId}
                                                        readOnly={isFxBarReadOnly}
                                                        driversAndWorksheetData={{
                                                            dataLoading,
                                                            parsedData,
                                                            rawData,
                                                            saveData,
                                                        }}
                                                        editableFxBarChecker={buildEditableFxBarChecker(user, accountLockedForVersionType)}
                                                    /></div>
                                                : <></>
                                        }
                                        {
                                            config.isReady && config.properties.currentProperty && account && combinedData ?
                                            <AccountTable
                                                key={account.id + config.properties.currentProperty.id}
                                                year={year}
                                                versionType={VersionType.Reforecast}
                                                versionId={versionId}
                                                property={config.properties.currentProperty}
                                                account={account}
                                                combinedData={combinedData}
                                                forecastLocks={forecastLocks}
                                                lockOverrides={lockOverrides}
                                                forceDataRefetch={fetchItAll}
                                                dataUpdated={updateGraphs}
                                            />
                                            : <div className={workflowCSS.tableSkeletonLoader}>
                                                    <Skeleton height={"220px"} width={"100%"} />
                                                    <table>
                                                        <tbody>
                                                            {Array(4).fill(null).map((_, index) =>
                                                                <tr key={index}>
                                                                    {Array(14).fill(null).map((_, indexTd) =>
                                                                        <td key={indexTd} />
                                                                    )}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                        }
                                    </div>
                                    <div className={workflowCSS.actionsCol}>
                                        <AccountInstruction
                                            accountId={accountId}
                                            versionId={versionId}
                                        />
                                        <AccountNote
                                            propertyId={config.properties.currentProperty?.id}
                                            accountId={accountId}
                                            year={config.properties.currentProperty?.reforecastYear ?? 0}
                                            versionType={VersionType.Reforecast}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel item={WORKFLOW_YEAR_TAB_BUDGET} className={workflowCSS.tabContent}>
                                    <div className={workflowCSS.dataColumn}>
                                        <div className={cn(workflowCSS.accountTableContainer, workflowCSS.accountSummaryTable)}>
                                            {
                                                user && config.isReady && hasFeature(Feature.AccountFormulaBar) && (accountLockedForVersionType !== undefined)
                                                    ? <div className={css.accountFormulaBar}>
                                                        <YearMonthRange
                                                            startYear={config.year + 1}
                                                            startMonthIdx={0}
                                                            endYear={config.year + 1}
                                                            endMonthIdx={11}
                                                            className={css.formulaDateRange}
                                                        />
                                                        <FormulaBar
                                                            year={config.year}
                                                            budgetYear={config.year + 1}
                                                            versionType={VersionType.Budget}
                                                            currentPropertyId={config.properties.currentProperty?.id}
                                                            accountId={accountId}
                                                            readOnly={isFxBarReadOnly}
                                                            driversAndWorksheetData={{
                                                                dataLoading,
                                                                parsedData,
                                                                rawData,
                                                                saveData,
                                                            }}
                                                            editableFxBarChecker={buildEditableFxBarChecker(user, accountLockedForVersionType)}
                                                        /></div>
                                                    : <></>
                                            }
                                            {
                                                config.isReady && config.properties.currentProperty && account && combinedData ?
                                                    <AccountTable
                                                        key={account.id + config.properties.currentProperty.id}
                                                        year={year}
                                                        versionType={VersionType.Budget}
                                                        versionId={versionId}
                                                        property={config.properties.currentProperty}
                                                        account={account}
                                                        combinedData={combinedData}
                                                        forecastLocks={forecastLocks}
                                                        lockOverrides={lockOverrides}
                                                        forceDataRefetch={fetchItAll}
                                                        dataUpdated={updateGraphs}
                                                    />
                                                    : <div className={workflowCSS.tableSkeletonLoader}>
                                                        <Skeleton height={"220px"} width={"100%"} />
                                                        <table>
                                                            <tbody>
                                                                {Array(4).fill(null).map((_, index) =>
                                                                    <tr key={index}>
                                                                        {Array(14).fill(null).map((_, indexTd) =>
                                                                            <td key={indexTd} />
                                                                        )}
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={workflowCSS.actionsCol}>
                                        <AccountInstruction
                                            accountId={accountId}
                                            versionId={versionId}
                                        />
                                        <AccountNote
                                            propertyId={config.properties.currentProperty?.id}
                                            accountId={accountId}
                                            year={(config.properties.currentProperty?.reforecastYear ?? 0) + 1}
                                            versionType={VersionType.Budget}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                            {
                                config.isReady && config.chartOfAccountsConfig.isReady && accountId && parsedGraphFinancialData &&
                                <AccountSummary
                                    chartOfAccounts={config.chartOfAccountsConfig}
                                    accountId={accountId}
                                    parentCategories={parentCategories}
                                    configYear={config.year}
                                    rfcstStartMonth={config.startReforecastMonthIndex}
                                    financialYearValues={parsedGraphFinancialData}
                                    versionType={versionType}
                                />
                            }
                        </AccountSummaryContextProvider>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

// TODO: Determine if memoization is necessary. Moved memo wrapper here from AccountRoute.
// export default memo(Account);

export default Account;
