import { ReactElement, useMemo, useState } from "react";
import { ReportTableData, ReportTableDataRow } from "../logic/reportTablesLogic";
import { Body, Head, HeaderCell, HeaderRow, Row, Cell, Table, SortableCell } from "@zendeskgarden/react-tables";
import { formatterDollarUSNoDecimal, formatterPercentWithSign } from "../../../utils/formatters";
import css from "../styles/table.module.scss";
import { getVarianceColor } from "../../../utils/variance";
import {SortType, nextSortType} from "../../../utils/sorting";

type SortColumn = "LINE" | "TOTAL_RFCST" | "TOTAL_BUDGET" | "GROWTH_$" | "GROWTH_%" | "PER_UNIT";
type SortField = keyof Pick<ReportTableDataRow, "reforecastTotal"|"budgetTotal"|"varianceAmount"|"variancePercent"|"budgetPerUnit">;

export function FinancialReportTable(props: {reforecastYearLabel: number | string, budgetYear: number, data: ReportTableData, customReforecastVsLabel?: string }): ReactElement {
    const {reforecastYearLabel, budgetYear, data} = props;
    const [sortColumn, setSortColumn] = useState<SortColumn>();
    const [sortType, setSortType] = useState<SortType>();

    function handleSortUpdate(column: SortColumn | undefined) {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortType(nextSortType(undefined));
        }
        else {
            setSortType(nextSortType(sortType));
        }
    }

    function currentSortForColumn(column: SortColumn | undefined): SortType | undefined {
        let type: SortType | undefined = undefined;
        if (!column) {
            type = undefined;
        }
        else if (sortColumn === column) {
            type = sortType;
        }

        return type;
    }

    const processedData = useMemo(() => {
        let ret = data.rows;
        let sortField:SortField|undefined = undefined;
        let sortAscending:boolean|undefined = undefined;
        if (sortColumn && sortType) {
            if (sortColumn === "TOTAL_RFCST") {
                sortField = "reforecastTotal";
            }
            else if (sortColumn === "TOTAL_BUDGET") {
                sortField = "budgetTotal";
            }
            else if (sortColumn === "GROWTH_$") {
               sortField = "varianceAmount";
            }
            else if (sortColumn === "GROWTH_%") {
                sortField = "variancePercent";
            }
            else if (sortColumn === "PER_UNIT") {
               sortField = "budgetPerUnit";
            }
            if (sortType === "asc") {
                sortAscending = true;
            }
            else if (sortType === "desc") {
                sortAscending = false;
            }
            if (sortField !== undefined && sortAscending !== undefined) {
                if (sortAscending) {
                    ret = data.rows.sortBy(sortField);
                }
                else {
                    ret = data.rows.sortByDescending(sortField);
                }
            }
        }
        return ret;
    }, [sortColumn, sortType, data.rows]);

    return (
        <div className={css.wrapper}>
            <div className={css.topHeader}>
                <div>
                    <h4>{data.name}</h4>
                    <h5>{props.customReforecastVsLabel && props.customReforecastVsLabel + " vs "}{budgetYear % 100} BDGT</h5>
                </div>
            </div>
            <Table className={css.table}>
                <Head>
                    <HeaderRow>
                        <HeaderCell>Line</HeaderCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("TOTAL_RFCST")}
                            sort={currentSortForColumn("TOTAL_RFCST")}
                        >
                            {reforecastYearLabel} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("TOTAL_BUDGET")}
                            sort={currentSortForColumn("TOTAL_BUDGET")}
                        >
                            {budgetYear} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("GROWTH_$")}
                            sort={currentSortForColumn("GROWTH_$")}
                        >
                            Growth $
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("GROWTH_%")}
                            sort={currentSortForColumn("GROWTH_%")}
                        >
                            Growth %
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("PER_UNIT")}
                            sort={currentSortForColumn("PER_UNIT")}
                        >
                            {budgetYear} Per Unit
                        </SortableCell>
                    </HeaderRow>
                </Head>
                <Body>
                    {processedData.map(row => (
                        <Row key={row.accountId}>
                            <Cell>{row.name}</Cell>
                            <Cell>{row.reforecastTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.reforecastTotal)}</Cell>
                            <Cell>{row.budgetTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetTotal)}</Cell>
                            <Cell style={{
                                color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                            }}>
                                {row.varianceAmount === null ? "-" : formatterDollarUSNoDecimal.format(row.varianceAmount)}
                            </Cell>
                            <Cell style={{
                                color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                            }}>
                                {row.variancePercent === null ? "-" : formatterPercentWithSign.format(row.variancePercent)}
                            </Cell>
                            <Cell>{row.budgetPerUnit === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetPerUnit)}</Cell>
                        </Row>
                    ))}
                </Body>
            </Table>
        </div>
    );
}