import { ApolloError } from '@apollo/client';
import { ReactElement, useContext, useEffect } from 'react';
import { BudgetingType } from '../../../BudgetingType';
import { allowRightClickCopyPaste, NewSpreadsheet } from '../../../components/spreadsheet/NewSpreadsheet';
import { useNewSpreadsheetAPI } from '../../../components/spreadsheet/NewSpreadsheetHooks';
import { useSettings } from '../../../contexts/settings/SettingsContext';
import useGetMonths from '../../../hooks/UseGetMonths';
import { OperationalMetric } from '../../../__generated__/generated_types';
import { useSaveOperationalMetric } from './base';
import { OperationalFinancialMetrics } from './operational-metrics';
import viewTemplate from "./operational_metric_view.json";
import { AuthContext } from "../../../contexts/AuthContext";
import { userIsReadOnly } from "../../../authorization/AuthorizationCheckers";
import { spreadsheetContainer } from "../account/styles/styles.module.scss";


type OperationalMetricSub<TKey extends keyof OperationalMetric> = {
    id: string;
    year: number;
    operationalMetrics: Pick<OperationalMetric, TKey | "monthIndex" | "id">[]
};

type QueryResults<TKey extends keyof OperationalMetric> = {
    actuals: OperationalMetricSub<TKey>[],
    budget?: OperationalMetricSub<TKey>[],
    currentVersion: OperationalMetricSub<TKey>[],
    reforecastVersion: OperationalMetricSub<TKey>[],
}


interface Data<TKey extends keyof OperationalMetric> {
    data?: QueryResults<TKey>;
    loading: boolean;
    error?: ApolloError;
}


interface Props<TKey extends keyof OperationalMetric = keyof OperationalMetric> {
    currentVersionYear: number;
    type: BudgetingType;
    propertyId: string;
    field: TKey;
    data: Data<TKey>;
    parseValue: (data: string) => string | number;
    parseFromRemote?: (data: string | number) => string | number | undefined;

    formatPercentage?: boolean;

    aggregator: "SUM" | "AVERAGE";

    key: string;

}

const FIRST_VALUE_COLUMN = 5;
const FIRST_VALUE_ROW = 5;
const REFORECAST_INPUT_ROW = 2;
const REFORECAST_INPUT_COL = 6;
const FORMAT_AS_PCT_ROW = 2;
const FORMAT_AS_PCT_COL = 9;
const AGGREGATOR_ROW = 2;
const AGGREGATOR_COL = 12;

const AGGREGATOR = {
    "SUM": 0,
    "AVERAGE": 1
};


function getDataDetails<TKey extends keyof OperationalMetric>(data: QueryResults<TKey>) {
    const actualRows = data.actuals.toIdMap("year", row => row.operationalMetrics.toIdMap(metrics => metrics.monthIndex.toString()) ?? {});

    const currentVersion = data.currentVersion.find(version => version.operationalMetrics.isNotEmpty) ?? data.currentVersion.firstElement;
    const currentVersionGroups = currentVersion?.operationalMetrics?.toIdMap(unit => unit.monthIndex.toString()) ?? {};
    const reforecastVersion = data.reforecastVersion.find(version => version.operationalMetrics.isNotEmpty) ?? data.reforecastVersion.firstElement;
    const reforecastVersinoGroups = reforecastVersion?.operationalMetrics?.toIdMap(unit => unit.monthIndex.toString()) ?? {};

    return { actualRows, currentVersion, currentVersionGroups, reforecastVersion, reforecastVersinoGroups };
}

export default function OperationalMetricTable<T extends keyof OperationalMetric>(props: Props<T> & {readOnly?: boolean}): ReactElement {

    const {

        type,
        currentVersionYear,

        field,
        propertyId,

        readOnly,
    } = props;

    const { loading, error, data } = props.data;
    const { user } = useContext(AuthContext);

    const spreadsheet = useNewSpreadsheetAPI();

    const saveMetric = useSaveOperationalMetric(propertyId, field);
    const { startReforecastMonthIndex } = useSettings();
    const months = useGetMonths();

    useEffect(
        () => {
            if (spreadsheet.isSpreadsheetReady) {
                spreadsheet.api.setTemplate({template: viewTemplate});
            }
        },
        [spreadsheet.isSpreadsheetReady]
    );

    useEffect(
        () => {
            if (data && !error && !loading && spreadsheet.isSpreadsheetReady) {
                spreadsheet.api.setValue({row: AGGREGATOR_ROW, col: AGGREGATOR_COL, value: AGGREGATOR[props.aggregator]});
                spreadsheet.api.setValue({row: FORMAT_AS_PCT_ROW, col: FORMAT_AS_PCT_COL, value: props?.formatPercentage ? 1 : 0});
                spreadsheet.api.setValue({row: REFORECAST_INPUT_ROW, col: REFORECAST_INPUT_COL, value: type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0});
                for (let col = type == BudgetingType.REFORECAST ? FIRST_VALUE_COLUMN + startReforecastMonthIndex : FIRST_VALUE_COLUMN; col < FIRST_VALUE_COLUMN + 12; col++) {
                    spreadsheet.api.setCellLocked({row: FIRST_VALUE_ROW, col, locked: false});
                }
                const lockedColumnsCount = type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0;
                if (lockedColumnsCount > 0 ) {
                    spreadsheet.api.setCellsLocked({row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN, rowCount: 1, colCount: lockedColumnsCount, locked: true});
                }
                const unLockedColumnsCount = 12 - lockedColumnsCount;
                if (unLockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN + lockedColumnsCount, rowCount: 1, colCount: unLockedColumnsCount, locked: false});
                }

                const { actualRows, currentVersionGroups, reforecastVersinoGroups } = getDataDetails(data);
                const dataArray: (string | number)[][] = [["Year", ...months],
                ...(new Array(5).fill(0).map((_, index) => {
                    const year = currentVersionYear - index;
                    const actuals = actualRows[year] ?? {};
                    const isFirstRow = year === currentVersionYear;
                    const isSecondRow = index == 1;
                    const yearRow: (string | number)[] = [
                        year.toString() + (isFirstRow ? (type == BudgetingType.BUDGET ? " Budget" : " Reforecast") : ""),
                        ...(new Array(12).fill(0).map((_, monthIndex) => {
                            const isEditable = isFirstRow && (type == BudgetingType.BUDGET || monthIndex >= startReforecastMonthIndex);
                            const currentValue = currentVersionGroups[monthIndex];
                            const reforecastValue = reforecastVersinoGroups[monthIndex];
                            const actual = actuals[monthIndex];
                            const numericCurrent = currentValue?.[field];
                            const numericActual = actual?.[field];
                            const numericReforecast = reforecastValue?.[field];

                            if (isSecondRow && type == BudgetingType.BUDGET && monthIndex >= startReforecastMonthIndex) {
                                return (numericReforecast ? parseFromRemote(numericReforecast) : undefined) as number;
                            }
                            return (isEditable ? (numericCurrent ? parseFromRemote(numericCurrent) : undefined) : (numericActual ? parseFromRemote(numericActual) : undefined)) as number;
                        }))
                        // <vztd formula={(row, column) => `=IFERROR(${props.aggregator}(R${row + 1}C2:R${row + 1}C${column}), "")`} style={{...(isFirstRow ? DATA_CELL_TOTAL_STYLE : DATA_CELL_STYLE), ...(props.valueReadFormat)}} />
                    ];

                    return yearRow;
                }))];
                spreadsheet.api.setArray({row: FIRST_VALUE_ROW - 1, col: FIRST_VALUE_COLUMN - 1, array: dataArray});

                if(userIsReadOnly(user)){
                    spreadsheet.api.lockAllCells();
                }

                spreadsheet.api.directAccess(spread => {
                    spread.options.allowUserDragDrop = false;
                    spread.options.showDragFillSmartTag = false;
                    allowRightClickCopyPaste(spread);
                });
            }
        },
        [spreadsheet.isSpreadsheetReady, data]
    );

    useEffect(
        () => {
            if (!data) {
                return;
            }
            const cells = spreadsheet.cellsChanged.filter(cell => cell.row == FIRST_VALUE_ROW && cell.col >= FIRST_VALUE_COLUMN && cell.col <= FIRST_VALUE_COLUMN + 11).sortBy("col");

            if (cells.length == 0) {
                return;
            }

            const { currentVersion } = getDataDetails(data);

            const values = [];
            const monthIndexes = [];
            for (const cell of cells) {
                const monthIndex = cell.col - FIRST_VALUE_COLUMN;
                monthIndexes.push(monthIndex);
                values.push(props.parseValue(cell.value == null || isNaN(cell.value) ? "0" : cell.value.toString()).toString());
            }
            saveMetric(currentVersion.id, monthIndexes, values)
        },
        [spreadsheet.cellsChanged]
    );


    const parseFromRemote = props.parseFromRemote ?? ((value: string) => parseInt(value));


    return (
        <>
            <div className={`operational-table-container table-average-rent bg-white p-0 ${spreadsheetContainer}`}>
                <NewSpreadsheet startRow={4} startCol={4} rows={5} cols={14} handlers={spreadsheet.handlers} subscribeToMouse={false} readOnly={readOnly} />
            </div>
            <OperationalFinancialMetrics type={type} />
        </>
    );
}
