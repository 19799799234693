import {NewSpreadsheet} from '../../../../../../components/spreadsheet/NewSpreadsheet';
import {OperationalFinancialMetrics} from '../../../operational-metrics';
import {ReactElement, useEffect} from 'react';
import {useNewSpreadsheetAPI} from '../../../../../../components/spreadsheet/NewSpreadsheetHooks';
import useGetMonths from '../../../../../../hooks/UseGetMonths';
import viewTemplate from "./templates/cappedRenewalIncreaseSjsTemplate.json";
import {CustomCellType} from "../../../../../../components/spreadsheet/NewSpreadsheetTypes";
import {FIRST_VALUE_COLUMN, FIRST_VALUE_ROW, Props, SheetNames, SheetsIndexMap} from "./cappedRenewalIncreaseSjsConfig";
import {
    getPropertyLevelBudgetDataArray,
    getPropertyLevelReforecastDataArray, getUnitTypeLevelBudgetDataArray,
    getUnitTypeLevelReforecastDataArray,
    updatePropertyLevelSheetViewPort,
    updateUnitTypeLevelSheetViewPort
} from "./utils";
import {BudgetingType} from "../../../../../../BudgetingType";

export default function CappedRenewalIncreaseSjs({
                                                     data,
                                                     budgetingType,
                                                     year,
                                                     startReforecastMonthIndex,
                                                     canRender,
                                                     readOnly
                                                 }: Props): ReactElement {

    const {
        handlers: sshandlers,
        isSpreadsheetReady,
        api: ssapi,
        cellTargetClicked,
    } = useNewSpreadsheetAPI();

    const months = useGetMonths();

    useEffect(
            () => {
                if (isSpreadsheetReady) {
                    ssapi.setTemplate({template: viewTemplate});
                }
            },
            [isSpreadsheetReady]
    );

    useEffect(() => {
                // console.info(`useEffect: ${isSpreadsheetReady}, ${JSON.stringify(data)}, ${canRender}`);
                // console.info(`useEffect: isSpreadsheetReady && data && canRender`);
                if (isSpreadsheetReady && data && canRender) {
                    ssapi.directAccess(spread => {
                        spread.options.allowUserDragDrop = false;
                        spread.options.allowContextMenu = false;
                        spread.options.showDragFillSmartTag = false;
                        const unitTypeLevelSheet = spread.getSheet(SheetsIndexMap[SheetNames.UNIT_TYPE_LEVEL]);
                        /*
                        1 has been added data.unitTypes count below to entertain the total rows below
                         */
                        for (let i = 0; i < (data.unitTypes.length + 1) * 3; i = i + 3) {
                            unitTypeLevelSheet.addSpan(
                                    FIRST_VALUE_ROW + 1 + i,
                                    FIRST_VALUE_COLUMN,
                                    3,
                                    1);
                        }
                    });

                    let plDataArray: string[][];
                    let utlDataArray: string[][];

                    if (budgetingType === BudgetingType.REFORECAST) {
                        plDataArray = getPropertyLevelReforecastDataArray(data, year, startReforecastMonthIndex);
                        utlDataArray = getUnitTypeLevelReforecastDataArray(data, year, startReforecastMonthIndex);
                    } else {
                        plDataArray = getPropertyLevelBudgetDataArray(data, year, startReforecastMonthIndex);
                        utlDataArray = getUnitTypeLevelBudgetDataArray(data, year);
                    }

                    ssapi.suspend();
                    ssapi.clearSelection();

                    // Populate the Property Level data
                    ssapi.setArray({
                        row: FIRST_VALUE_ROW,
                        col: FIRST_VALUE_COLUMN + 2,
                        array: [months],
                        sheetName: SheetNames.PROPERTY_LEVEL
                    });
                    ssapi.setArray({
                        row: FIRST_VALUE_ROW + 1,
                        col: FIRST_VALUE_COLUMN,
                        array: plDataArray,
                        sheetName: SheetNames.PROPERTY_LEVEL
                    });
                    ssapi.applyCustomCellType({
                        row: FIRST_VALUE_ROW + 1,
                        col: FIRST_VALUE_COLUMN,
                        cellType: CustomCellType.EXPAND_ROW_CELL,
                        sheetName: SheetNames.PROPERTY_LEVEL
                    });
                    // Ensure the cells are readonly
                    ssapi.lockAllCells({sheetName: SheetNames.PROPERTY_LEVEL});

                    // Populate the Unit Type Level data
                    ssapi.setArray({
                        row: FIRST_VALUE_ROW,
                        col: FIRST_VALUE_COLUMN + 2,
                        array: [months],
                        sheetName: SheetNames.UNIT_TYPE_LEVEL
                    });
                    ssapi.setArray({
                        row: FIRST_VALUE_ROW + 1,
                        col: FIRST_VALUE_COLUMN,
                        array: utlDataArray,
                        sheetName: SheetNames.UNIT_TYPE_LEVEL
                    });
                    ssapi.applyCustomCellType({
                        row: FIRST_VALUE_ROW + (data.unitTypes.length * 3) + 1, // 3*2 unit types
                        col: FIRST_VALUE_COLUMN,
                        cellType: CustomCellType.EXPAND_ROW_CELL,
                        sheetName: SheetNames.UNIT_TYPE_LEVEL
                    });
                    // Ensure the cells are readonly
                    ssapi.lockAllCells({sheetName: SheetNames.UNIT_TYPE_LEVEL});

                    // By default show the property level sheet
                    updatePropertyLevelSheetViewPort(ssapi);
                    ssapi.resume();
                }
            }, [isSpreadsheetReady, data, canRender, budgetingType]
    );

    useEffect(() => {
                if (cellTargetClicked) {
                    const activeSheetIndex = ssapi.getActiveSheetIndex();
                    const propertySheetIndex = ssapi.getSheetIndex(SheetNames.PROPERTY_LEVEL);
                    if (activeSheetIndex === propertySheetIndex) {
                        updateUnitTypeLevelSheetViewPort(ssapi, data?.unitTypes.length ?? 0);
                    } else {
                        updatePropertyLevelSheetViewPort(ssapi);
                    }
                }
            },
            [cellTargetClicked]
    );

    return (
        <>
            <div className="operational-table-container bg-white p-0">
                <NewSpreadsheet
                        startRow={4}
                        startCol={4}
                        rows={5}
                        cols={15}
                        handlers={sshandlers}
                        readOnly={readOnly}
                        subscribeToMouse={true}/>
            </div>
            <OperationalFinancialMetrics type={budgetingType}/>
        </>
    );
}
